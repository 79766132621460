import React, { Component } from "react";
import "../../common/hero/hero.css";
import "../signup/home.css";
import { Auth, API } from "aws-amplify";
import "./customStoryImport/customStoryImport.css";
import { Link, Redirect } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "../../common/styles/common.css";
import FAQ, { FAQItem } from "./FAQ";
import animeImage from "../../images/animeImage.jpeg";
import comicImage from "../../images/comicImage.jpeg";
import childrensImage from "../../images/childrens.jpeg";
import cyberAnime from "../../images/cyberAnime.jpeg";
import cyberComic from "../../images/cyberComicBook.jpeg";
import mangaImage from "../../images/manga.jpeg";
import japanesePanel from "../../images/japanesePanel.jpeg";
import ComicBookEditor from "./imageEditor/ComicBookEditor";
import { Button, Carousel } from "react-bootstrap";

interface CustomStoriesProps {}

export interface Story {
  id: string;
  userId: string;
  genre: string;
  storyType: number;
  prompt: string;
  createdAt: number;
  title: string;
  fileId: string;
  sceneCount: string;
  isPrivate: boolean;
  metadata: Metadata;
  updatedAt: number;
  status: number;
}

export interface PaginatedStoriesResponse {
  stories: Story[];
  totalCount: number;
}

export interface Metadata {
  bookName: string;
  author: string;
  bookCoverS3Url: string;
}

interface PastDownloadsState {
  userInfo: any; // FIXME
  isLoading: boolean;
  redirectToPublicStories: boolean;
  redirectToCustomStories: boolean;
  redirectToCustomStoryId: string;
  email:string;
  index: number;
  direction: string | undefined;
}

const faqItems: FAQItem[] = [
  {
    question: 'What are AI-Generated Graphic Novels?',
    answer: 'AI-Generated Graphic Novels are unique visual stories created with the help of GPT-4 and Stable Diffusion. Our platform uses AI to generate tailor-made graphic novels, such as comics and anime, based on user-provided text prompts.',
  },
  {
    question: 'Is there any cost to use the platform?',
    answer: 'We offer both free and paid plans. The free plan provides limited access to the AI-generated graphic novel features, while the paid plans offer advanced features and additional customization options. Visit our profile/billing page for more details.',
  },
  {
    question: 'Can I save or export the generated graphic novels?',
    answer: 'Yes, you can save your generated graphic novels to your account or export them in various formats, such as PDF or image files, for easy sharing and printing.',
  },
  {
    question: 'How do I get started?',
    answer: 'To get started, sign up for an account on our platform. Once you registered, you can begin exploring the AI-generated graphic novel features and create your own unique stories.',
  },
  {
    question: 'Can I customize the generated graphic novels?',
    answer: 'Yes, you can customize generated graphic novels by adding new chapters to existing novels. We plan to have more complex custimization coming to CSC (Custom Story Creation) soon.',
  },
  {
    question: 'Do I need any technical or design skills to use the platform?',
    answer: 'No, you do not need any technical or design skills to use our platform. The AI takes care of generating the visuals and narratives, and all you need to do is provide the text prompts and select the desired customization options.',
  }
];


export default class CustomStories extends Component<CustomStoriesProps, PastDownloadsState> {
  constructor(props: CustomStoriesProps) {
    super(props);

    this.state = {
      userInfo: null,
      isLoading: true,
      redirectToPublicStories: false,
      redirectToCustomStories: false,
      redirectToCustomStoryId: '',
      email:'',
      index: 0,
      direction: 'next'
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleCarouselSelect = this.handleCarouselSelect.bind(this);
  }

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();
    if (userInfo === null) {
      this.setState({ 
        isLoading: false
     });
      return;
    }
    
    try {
      this.setState({ 
        userInfo: userInfo, 
        email: userInfo.attributes.email,
        isLoading: false
     });
    } catch (e) {
      alert(e);
    }
  }
  
  userIsAuthenticated = () => {
    return this.state.email !== '';
  }

  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
  }  

  goToPublicStories = async () => {
    this.setState({redirectToPublicStories: true});
  }

  listPublicStories= (pageToFetch: number) =>  {
    return API.get("story", "/public/paginated?page=" + pageToFetch, null);
  }
  
  getStory = (storyId: string) =>{
    return API.get("story", "/" + storyId, null);
  }

  onImageClicked = (storyId: string) => {
    // const target = event.target as HTMLInputElement;
    this.setState({
      redirectToCustomStories: true,
      redirectToCustomStoryId : storyId
    });
  }

  handleSelect(selectedIndex: number, e: any) {
    this.setState({
      index: selectedIndex,
      direction: e?.direction,
    });
  }

  handleCarouselSelect(selectedIndex: number) {
    this.setState({
      index: selectedIndex
    });
  };

  handleClick = () => {
    // Replace the URL with your external URL
    const url = 'https://www.oculus.com/experiences/quest/6161303843907695/?utm_source=oculusapplab.com';
    window.location.href = url;
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="white-box">
          <div className="media">
            <div className="media-left media-middle">
              <div className="loader-no-margin" />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.redirectToCustomStories) {
      return (
        <div className="white-box">
          <div className="media">
            <Redirect to={{
                pathname: '/customStories/' + this.state.redirectToCustomStoryId
            }}
            />
          </div>
        </div>
      );
    }

    if (this.state.redirectToPublicStories === true) {
      return <Redirect to='/publicStories' />;
    }
    
    return (
      <div className="Category">
            <meta name="description" content="Immerse Yourself in AI-Crafted Anime and Comic Books: Unlock the Boundless World of Personalized Graphic Novels. Unleash Your Imagination and create one-of-a-kind comics or anime that combine humor, drama, and action with our AI-powered platform."></meta>
            <div className="banner">

            {/* <h1 className="text-white-header text-center">Discover the World of AI Generated Anime and Comic Books</h1> */}
            {/* <br></br> */}
            <Carousel interval={2000}>
            <Carousel.Item>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                  <img
                    src={animeImage}
                    style={{ textAlign: 'center' }}
                    alt="Anime"
                    width={800} 
                    height={550} 
                    // className={`img-responsive genre-image`}
                    onClick={() => this.onImageClicked("3b188a8c-334b-42df-9c43-398c71bce9ab")}
                  />
                  <Carousel.Caption>
                    <h3>Anime</h3>
                    <p>An hero's journey to become the world's greatest warrior</p>
                  </Carousel.Caption>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                  <img
                    src={comicImage}
                    style={{ textAlign: 'center' }}
                    alt="Comic Book"
                    width={800} 
                    height={550} 
                    // className={`img-responsive genre-image`}
                    onClick={() => this.onImageClicked("5e8f3687-d63e-43d0-980b-46a551565842")}
                  />
                  <Carousel.Caption>
                    <h3>Comic Book</h3>
                    <p>A parallel universe where historical figures are superheroes</p>
                  </Carousel.Caption>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                  <img
                    src={childrensImage}
                    style={{ textAlign: 'center' }}
                    alt="Children's Book"
                    width={800} 
                    height={550} 
                    // className={`img-responsive genre-image`}
                    onClick={() => this.onImageClicked("3c0c9e01-53ab-4ce9-acfc-8ca870c83c3d")}
                  />
                  <Carousel.Caption>
                    <h3>Children's Book</h3>
                    <p>A rabbit teaches a lonely child the value of friendship.</p>
                  </Carousel.Caption>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                  <img
                    src={mangaImage}
                    style={{ textAlign: 'center' }}
                    alt="Manga"
                    width={800} 
                    height={550} 
                    // className={`img-responsive genre-image`}
                    onClick={() => this.onImageClicked("10c9e39d-35c5-461e-b23d-782034a8ba0b")}
                  />
                  <Carousel.Caption>
                    <h3>Manga</h3>
                    <p>A time-traveling woman battles ancient demons in modern times.</p>
                  </Carousel.Caption>
              </div>
            </Carousel.Item>
              </Carousel>
              
              <br></br>
              <br></br>
              <br></br>
              <br></br>              
              <br></br>
              {/* <br></br> */}
              {/* <h1 className="text-white-header text-center">Discover the World of AI Generated Anime and Comic Books</h1> */}
              <div className="row">
                <h1 className="text-white-header" style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '38px',
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  // backgroundColor: 'rgba(44, 62, 80, 0.8)',
                  borderRadius: '10px',
                  padding: '15px',
                  color: '#ffffff',
                  // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}>
                  Custom Story Creator
                </h1>
              </div>
              <div className="row">
                {/* <h1 className="text-white-header" style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '38px',
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  // backgroundColor: 'rgba(44, 62, 80, 0.8)',
                  borderRadius: '10px',
                  padding: '15px',
                  color: '#ffffff',
                  // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}>
                  Custom Story Creator
                </h1>
              <br></br>
              <br></br>
              <br></br> */}

                <div className="col-sm-3 col-md-3">
                </div>
                <div className="col-sm-6 col-md-6">
                  <br></br>
                  <br></br>
                  <p className="text-white-large">
                    Experience AI-driven personalized storytelling with our platform, focusing on the creation of tailor-made graphic novels. 
                    Delve into the limitless potential of generative visual narratives and ignite your imagination
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
                <div className="col-sm-3 col-md-3">
                <div>
                </div>
                <br></br>
              </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-3">
                </div>
                <div className="col-12 col-md-6">
                  <div className="video-container">
                    <iframe className="responsive-video" src="https://www.youtube.com/embed/xqKJfXeuNd4" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-1">
              </div>
              { this.state.email === '' && 
                  <div className="col-12 col-md-4">              
                    <div className="col-md-12">
                      <div className="center-screen">
                        <Link to="/customStories">
                          <img
                            style={{ backgroundColor: "#1e1d1d" }}
                            src="https://media.giphy.com/media/bedENpt36kl9lGgfMm/giphy.gif"
                            alt="Anime"
                            className={`img-responsive genre-image`}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="center-screen">
                        <div className="text-center">
                          <LinkContainer to="/signup">
                            <a href="/signup" className="custom-button">Sign up</a>
                          </LinkContainer>
                          &nbsp;&nbsp;&nbsp;
                          <LinkContainer to="/login">
                            <a href="/login" className="custom-button">Login</a>
                          </LinkContainer>
                        </div>
                      </div>
                    </div>              
                  </div> 
                }
                { this.state.email !== '' && 
                  <div className="col-12 col-md-4">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h3 className="text-white-header text-center" style={{color: '#ff4081', fontSize: '3.5rem', fontWeight: 'bold', textShadow: '2px 2px #000'}}>
                      Tap below to create your first graphic novel
                    </h3>
                    <h3 className="text-white-header text-center" style={{color: '#ff4081', fontSize: '2.0rem',  textShadow: '2px 2px #000'}}>
                      Its completely free to try out!
                    </h3>
                    <div className="col-md-12">
                      <div className="center-screen">
                        <Link to="/customStories">
                          <img
                            style={{ backgroundColor: "#1e1d1d" }}
                            src="https://media.giphy.com/media/SqkSdfaiSqBjbVxzzd/giphy.gif"
                            alt="Anime"
                            className={`img-responsive genre-image`}
                          />
                        </Link>
                      </div>
                      <br></br>
                    </div>
                  </div> 
                }
                <div className="col-12 col-md-2">
                </div>
                <div className="col-12 col-md-4">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1 className="text-white-header">Unleash Your Imagination</h1>
                    <br></br>
                    <br></br>
                    <p className="text-white-large">
                      Immerse Yourself in AI-Crafted Anime and Comic Books: Unlock the Boundless World of Personalized Graphic Novels. 
                      Unleash Your Imagination and create one-of-a-kind comics or anime that combine humor, drama, and action with our AI-powered platform. 
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    { this.state.email !== '' && 
                      <LinkContainer to="/customStories">
                        <a href="/customStories" className="custom-button">Try out now!</a>
                      </LinkContainer>
                    }
                    <br></br>
                    {/* <div className="center-screen">
                      <div className="text-center">
                        <LinkContainer to="/signup">
                          <a href="/signup" className="custom-button">Sign up</a>
                        </LinkContainer>
                      </div>
                    </div> */}
                </div>
                <div className="col-12 col-md-1">
                </div>
              </div>
              <div className="row">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className="col-md-12">
                    <div className="center-screen">
                    <img
                        background-color="black"
                        src="https://media.giphy.com/media/2xPMQ5gAycZhL0l9O3/giphy.gif"
                        alt="Anime"
                        className={`img-responsive genre-image`}
                        onClick={() => this.onImageClicked("3b188a8c-334b-42df-9c43-398c71bce9ab")}
                      />
                    </div>
                    <br></br>
                  </div>
              </div>

              <div className="row">
                {/* <p className="text-white-medium">
                  Immerse Yourself in AI-Crafted Anime and Comic Books: Unlock the Boundless World of Personalized Graphic Novels. 
                  Unleash Your Imagination and create one-of-a-kind comics or anime that combine humor, drama, and action with our AI-powered platform. 
                </p> */}
                <div className="col-12 col-md-1">
                </div>
                <div className="col-12 col-md-4">
                  <h1 className="text-white-header">Cyberpunk Anime <small>(New)</small></h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p className="text-white-large">
                    Enter a vibrant, AI-generated universe of high-tech and high-stakes with our CyberPunk Anime generator. Craft unique narratives that blend Japanese animation aesthetics with the dark allure of a neon-drenched dystopian future. Create characters from cybernetic samurais to hacker schoolgirls, and deliver riveting, visually stunning tales that transcend boundaries of imagination.
                  </p>
                  <br></br>
                  <br></br>

                </div>
                <div className="col-12 col-md-2">
                </div>
                <div className="col-12 col-md-4">
                <img
                    background-color="black"
                    src={cyberAnime}
                    alt="Cyber Anime"
                    className={`img-responsive genre-image`}
                    onClick={() => this.onImageClicked("d32d7c23-0225-4876-90b7-ef58b6d7094d")}
                  />
                <br></br>
                <br></br>
                <br></br>                  
                <br></br>
                <br></br>
                </div>
                <div className="col-12 col-md-1">
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-1">
                </div>
                <div className="col-12 col-md-4">
                  <h1 className="text-white-header">Cyberpunk Comic Book <small>(New)</small></h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p className="text-white-large">
                    Unleash your creativity in the frame-by-frame world of our CyberPunk Comic Book generator. With advanced AI algorithms, generate compelling stories set in a tech-noir future, packed with dynamic action, tension-filled dialogue, and striking artwork. From streetwise hackers to cyber-enhanced superheroes, this is your portal into the thrilling universe of Cyberpunk comics.                  
                    </p>
                    <br></br>
                    <br></br>
                </div>
                <div className="col-12 col-md-2">
                </div>
                <div className="col-12 col-md-4">
                  <img
                      background-color="black"
                      src={cyberComic}
                      alt="Cyber Comic Book"
                      className={`img-responsive genre-image`}
                      onClick={() => this.onImageClicked("0e489999-a58a-45ff-9b23-30b8ff9d5663")}
                    />
                <br></br>
                <br></br>
                <br></br>                  
                <br></br>
                <br></br>
                <br></br>                  
                <br></br>
                <br></br>
                </div>
                <div className="col-12 col-md-1">
                </div>
              </div>
              <br></br>                  
                <br></br>
                <br></br>
              <h1 className="text-white-header" style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '30px',
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  // backgroundColor: 'rgba(44, 62, 80, 0.8)',
                  borderRadius: '10px',
                  padding: '15px',
                  color: '#ffffff',
                  // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}>
                  Wait, there's more!
                </h1>
                <br></br>
              <div className="row">
              <br></br>
                    <br></br>                    <br></br>
                    <br></br>                    <br></br>
                    <br></br>
                <div className="col-12 col-md-1">
                </div>

                {/* <div className="col-12 col-md-2">
                </div> */}
                <div className="col-12 col-md-4">
                    <img
                        background-color="black"
                        src={japanesePanel}
                        alt="Japanese Panel"
                        className={`img-responsive genre-image`}
                        onClick={() => this.onImageClicked("c0e7d228-d9a5-400a-8699-70e8a950f98a")}
                      />
                  {/* <img
                    style={{ backgroundColor: "#1e1d1d" }}
                    src="https://media.giphy.com/media/luhhBnALiAqirUXdCD/giphy.gif"
                    alt="Different Languages"
                    className={`img-responsive genre-image`}
                  /> */}
                </div>
                <div className="col-12 col-md-2">
                </div>
                <div className="col-12 col-md-4">
                <br></br>                    <br></br>
                    <br></br>
                    <br></br>                    <br></br>
                    <br></br>
                  <h1 className="text-white-header">We support 92 Languages!</h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p className="text-white-large">
                  We're thrilled to announce a new era in personalized storytelling – now supporting over 92 languages, including Japanese and Korean languages in Custom Story Creator! 
                  Craft your narratives with deeper cultural authenticity as your characters voice dialogues in these languages.  
                    </p>
                    <br></br>
                    <p className="text-white-large">
                  Picture a cybernetic samurai in Tokyo declaring "この戦いを終わらせよう!" (Let's end this fight!) or a determined Seoul hacker whispering "나는 성공할 것이다" (I will succeed). 
                    
                    </p>
                    {/* <p className="text-white-large">
                  Our platform takes a giant leap beyond being just an AI-powered story creator; it's now a multicultural canvas for your imagination. Dive into this global experience and let your stories break language barriers. Start crafting your multilingual graphic novel today. Imagination knows no language, after all!  
                    
                    </p> */}
                    <br></br>
                    <br></br>
                </div>
                <br></br>
                    <br></br>                    <br></br>
                    <br></br>
              </div>

              <div className="row">
                <br></br>
                <br></br>                  
                <br></br>
                <br></br>
                <br></br>
                <br></br>                  
                <br></br>
                <br></br>
                <div className="col-12 col-md-1">
                <br></br>
                  <br></br>
                </div>
                <div className="col-12 col-md-4">
                  <h1 className="text-white-header">XR Reader</h1>
                  <h1 className="text-white-header"><small>Read your favorite anime in AR/VR</small></h1>
                  <br></br>
                  <p className="text-white-large">
                    Dive into the rich worlds of anime and comics like never before with our XR Reader application for Meta Quest 2 and Pro. 
                    Generate, explore, and share your favorite stories in immersive AR/VR - a revolutionary new way to experience narratives. Storytelling reimagined!
                  </p>
                  <br></br>
                  <br></br>     
                  <br></br>
                  <br></br>
                  <br></br>
                    <button
                      className="custom-button"
                      // bsSize="large"
                      type="submit"
                      onClick={() => this.handleClick()}>
                        Read on Meta Quest 2/PRO
                    </button>
                    <br></br>
                    <br></br>

                </div>
                <div className="col-12 col-md-1">
                </div>
                <div className="col-12 col-md-6">
                  <br></br>
                  <br></br>
                  <div className="video-container">
                    <iframe className="responsive-video" src="https://www.youtube.com/embed/fKGp-q2aRV8" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                  </div>
                </div>

                <div className="col-12 col-md-1">
                </div>
              </div>
            </div>
        <div>
            <br></br>
            <br></br>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
              <br></br>

              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
                <h1 className="text-white-header" style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '34px',
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  // backgroundColor: 'rgba(44, 62, 80, 0.8)',
                  borderRadius: '10px',
                  padding: '15px',
                  color: '#ffffff',
                  // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}>
                  Frequently Asked Questions (FAQ)
                </h1>
                <div className="center-screen">
                  <img
                    background-color="black"
                    src="https://media.giphy.com/media/ybSuZ0iYpSJtLQ30o0/giphy.gif"
                    alt="Question Image"
                    className={`img-responsive genre-image`}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>     
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
              <FAQ faqItems={faqItems} />

            <br></br>
            <br></br>
              </div>
              <div className="col-md-2">

              </div>
            </div>            
            {/* <div className="row" >

              <FAQ faqItems={faqItems} />

              <br></br>
              <br></br>

            </div> */}
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </div>
      </div>
    );
  }
}
