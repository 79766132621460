import React, { useState } from 'react';
import "./FAQ.css";

export interface FAQItem {
  question: string;
  answer: string;
}

interface FAQProps {
  faqItems: FAQItem[];
}

const FAQ: React.FC<FAQProps> = ({ faqItems }) => {
  const [activeIndex, setActiveIndex] = useState(-1); // No active index initially

  return (
    <div className="faq-container bg-black">
      {faqItems.map((item, index) => (
        <div className="faq-item row" key={index}>
          <button 
            className={`text-white-medium faq-question ${activeIndex === index ? 'active' : ''}`}
            onClick={() => setActiveIndex(activeIndex === index ? -1 : index)}
          >
            {item.question}
          </button>
          {activeIndex === index && <div className="faq-answer text-white-medium">{item.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;