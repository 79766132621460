import { Auth } from "aws-amplify";
import React, { Component, Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, MenuItem, Nav, Navbar, NavDropdown, NavItem, Row } from "react-bootstrap";
import "./App.css";
import { Routes } from "./Routes";
import logoTransparent from "./images/logoTransparent.png"

interface AppProps {
  history: any;
}

interface AppState {
  email: string;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  redirect: boolean;
  message: string;
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
  
    this.state = {
      email: '',
      isAuthenticated: false,
      isAuthenticating: true,
      redirect: false,
      message: ''
    };

    document.title = "AI Generated Graphic Novels - Custom Story Creator"
  }
  
  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }


  userHasAuthenticated = (authenticated: boolean) => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleNavigation = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
  saveUsersEmail = (email: string) => {
    this.setState({ email: email });
  }

  handleLogout = async () => {
    await Auth.signOut();
  
    this.userHasAuthenticated(false);
    this.saveUsersEmail('');
    localStorage.clear();
    this.props.history.push("/login");
    
    console.log("Logging out");
    // this.setState({redirect: true});
    this.forceUpdate();
    // window.location.reload();
  }

  sendEmail = (e: any) => {
    e.preventDefault();
    window.open(`mailto:mailto:info@extendedrealitybooks.com?subject=Contact&body=${this.state.message}`);
  }

  onMessageChanged = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      message: target.value
    });
  }

  showLoggedInBar = () => (
    <Fragment>
            <LinkContainer to="/">
              <MenuItem eventKey={4.1}> 
                <span className="orange line-height-24">About</span>
            </MenuItem>
            </LinkContainer>
            <LinkContainer to="/customStories">
              <MenuItem eventKey={4.1}> 
                <span className="orange line-height-24">Playground</span>
            </MenuItem>
            </LinkContainer>
            <LinkContainer to="/publicStories">
              <MenuItem eventKey={4.2}> 
                  <span className="orange line-height-24">Public Stories</span>
              </MenuItem>
            </LinkContainer>
            <LinkContainer to="/privateStories">
              <MenuItem eventKey={4.2}> 
                  <span className="orange line-height-24">You're Stories</span>
              </MenuItem>
            </LinkContainer>
            {/* <MenuItem eventKey={4.2} onClick={() => this.handleNavigation('https://extendedrealitybooks.com')}>
              <span className="orange line-height-24">AI Stories in AR/VR</span>
            </MenuItem> */}
            
          <NavDropdown eventKey={4}  onClick={(e) => e.stopPropagation()} className="orange line-height-20" title={
          <span className="orange line-height-24">
            {this.state.isAuthenticated &&localStorage.getItem("email")}
            </span>} id="basic-nav-dropdown">
            <LinkContainer to="/profile">
              <MenuItem eventKey={4.2}> 
                <span className="orange line-height-24">Profile & Billing</span>
              </MenuItem>
            </LinkContainer>
            <LinkContainer to="/logout">
              <MenuItem eventKey={4.1} onClick={this.handleLogout}> 
                <span className="orange line-height-24">Log out</span>
              </MenuItem>
            </LinkContainer>
          </NavDropdown>
    </Fragment>
  );

  showLoggedOutBar = () => (
    <Fragment>
      <LinkContainer to="/">
        <MenuItem eventKey={4.1}> 
          <span className="orange line-height-24">About</span>
        </MenuItem>
      </LinkContainer>
      <LinkContainer to="/publicStories">
        <MenuItem eventKey={4.2}> 
          <span className="orange line-height-24">Public Stories</span>
        </MenuItem>
      </LinkContainer>
      {/* <MenuItem eventKey={4.2} onClick={() => this.handleNavigation('https://extendedrealitybooks.com')}>
        <span className="orange line-height-24">AI Stories in AR/VR</span>
      </MenuItem> */}
      <LinkContainer to="/signup">
        <NavItem><span className="orange line-height-24">Sign up</span></NavItem>
      </LinkContainer>
      <LinkContainer to="/login">
        <NavItem><span className="orange line-height-24">Log in</span></NavItem>
      </LinkContainer>
    </Fragment>
  );

  render() {
    const childProps = {
      saveUsersEmail: this.saveUsersEmail,
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App" >
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <span className="orange"> <img src={logoTransparent} alt="custom story creator" />Custom Story Creator</span>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {this.state.isAuthenticated ? this.showLoggedInBar() : this.showLoggedOutBar()}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <br></br>
        <Routes isAuthenticated={childProps.isAuthenticated} userHasAuthenticated={childProps.userHasAuthenticated} saveUsersEmail={childProps.saveUsersEmail} email={this.state.email} />
        
        <footer className="custom-footer">
            {/* <Container> */}
                <Row>
                    <Col xs={4} md={2} className="text-md-left">
                        <img
                            src="https://media.giphy.com/media/Sh7FMlRTsgB8lO8WKJ/giphy.gif"
                            alt="Anime"
                            width={150}
                            height={150}
                            className="footer-img img-fluid"
                        />
                    </Col>
                    <Col xs={8} md={4} className="text-md-left">
                        <div className="about-section">
                            <h4 className="text-white-footer">About Us</h4>
                            <p className="text-white-footer">Extended Reality Books, creators of customstorycreator.com and XR Reader, is a groundbreaking venture that creates immersive reading experiences by integrating AI and extended reality technologies into the traditional art of storytelling.</p>
                            <p className="text-white-footer">©2023 by Extended Reality Books LLC. All rights reserved.</p>
                        </div>
                    </Col>
                    <Col xs={1} md={1} className="text-md-left">
                    </Col>

                    <Form onSubmit={this.sendEmail}>
                      <Col xs={6} md={4} className="text-md-left">
                        <br></br>
                          {/* <h5>Contact Us</h5> */}
                            <FormGroup controlId="formMessage">
                              <ControlLabel><p className="text-white-footer">Contact Us</p></ControlLabel>
                              <FormControl bsSize="large" as="textarea" rows={3} value={this.state.message} onChange={this.onMessageChanged} />
                            </FormGroup>
                      </Col>
                      <Col xs={4} md={1} className="text-md-left">
                        <br></br>
                        <br></br>
                        <br></br>
                        <Button bsSize="small" className="primary" type="submit">
                          Send Email
                        </Button>
                      </Col>
                    </Form>

                </Row>
                {/* <Row>
                    <Col className="text-center text-white-footer">
                        <p>©2023 by Extended Reality Books LLC. All rights reserved.</p>
                    </Col>
                </Row> */}
            {/* </Container> */}
        </footer>
        {/* <footer className="custom-footer">
          <br></br>
          <img
                  background-color="black"
                  src="https://media.giphy.com/media/Sh7FMlRTsgB8lO8WKJ/giphy.gif"
                  alt="Anime"
                  width={200}
                  height={200}
                  className={`img-responsive genre-image`}
                />
          <div className="footer-content">
            <p>Contact: <a href="mailto:info@extendedrealitybooks.com">info@extendedrealitybooks.com</a></p>
            <p>©2023 by Extended Reality Books LLC. All rights reserved.</p>
          </div>
        </footer> */}
      </div>
    );
  }
}

export default withRouter(App as any);