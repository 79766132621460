import React, { Component } from "react";
import "../../common/hero/hero.css";
import { CustomStoriesRow } from "./CustomStoriesRow";
import { Auth, API } from "aws-amplify";
import "./customStoryImport/customStoryImport.css";
import { Redirect } from "react-router-dom";
import { Story } from "./CustomStories";
import Pagination from "./Pagination";

interface YourStoriesProps {
  isXrReaderView: boolean;
}

export interface Metadata {
  bookName: string;
  author: string;
  bookCoverS3Url: string;
}

interface PublicStoriesState {
  // userInfo: any; // FIXME
  isLoading: boolean;
  isStoryBeingGenerated: boolean;
  stories: Story[];
  isXrReaderView: boolean;
  currentPage: number;
  totalPages: number;
  email:string;
}

export default class YourStories extends Component<YourStoriesProps, PublicStoriesState> {
  constructor(props: YourStoriesProps) {
    super(props);

    console.log(props.isXrReaderView);
    this.state = {
      // userInfo: null,
      isStoryBeingGenerated : false,
      isLoading: true,
      stories: [],
      isXrReaderView: props.isXrReaderView,
      totalPages: 1,
      currentPage: 1,
      email: ''
    };
  }

  async componentDidMount() {
    // const userInfo = await Auth.currentUserInfo();
    // this.setState({ userInfo: userInfo, email: userInfo.attributes.email })
    const userInfo = await Auth.currentUserInfo();
    var email = userInfo.attributes.email;
    try {
      const storiesResponse = await this.listStories(1, email);
      console.log(storiesResponse);
      this.setState({ 
        stories: storiesResponse.stories,
        isLoading: false,
        totalPages: Math.ceil(storiesResponse.totalCount / 9),
        currentPage: 1,
        email: email
     });
    } catch (e) {
      alert(e);
    }
  }

  listStories= (pageToFetch: number, email :string) =>  {
    return API.get("story", "/"+ email + "/paginated?page=" + pageToFetch, null);
  }

  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
  }  

  handlePageChange = async (page: number) => {
    console.log(page);
    this.setState({currentPage: page});

    const paginatedStoryResponse = await this.listStories(page, this.state.email);
    this.setState({stories: paginatedStoryResponse.stories});
    this.forceUpdate();
  };

  render() {
    return (
      <div className="Category">
        <br></br>
          {this.state.isXrReaderView === undefined && 
              <div className="white-box">
                <h2 className="text-white-header text-center">Stories created by {this.state.email}</h2>
              </div>  
          }
          {this.state.stories.length !== 0 && 
            <div>
              <div className="white-box">
                  {/* <h1 className="text-white-header text-center">Previously Created Stories</h1> */}
                  <div className="row display-fle new">
                  {!this.state.isLoading && this.state.stories && this.state.stories
                    .sort((file1, file2) => file2.createdAt - file1.createdAt)
                    .map(file => 
                    <div  key={file.id}>
                    <CustomStoriesRow email={this.state.email} story={file} key={file.id} progressBar={-99} isXrReaderView={false} isCustomStyle={false}/>
                  </div>)
                  }
                </div>
              </div >  
              <div className="pagination-style">
                <Pagination currentPage={this.state.currentPage} totalPages={this.state.totalPages} onPageChange={this.handlePageChange} />     
              </div>
            </div>
          }
          {/* <div className="row display-fle new">
            {!this.state.isLoading && this.state.stories.length !== 0 && this.state.stories
              .filter((file) => file.status === 2)
              .sort((file1, file2) => file2.createdAt - file1.createdAt)
              .slice(0, 40)
              .map(file => 
              <div  key={file.id}>
              <CustomStoriesRow email='' story={file} key={file.id} progressBar={-80} isXrReaderView={this.state.isXrReaderView} isCustomStyle={false}/>
            </div>)
            } 
          </div> */}
          {/* <div className="pagination-style">
            <Pagination currentPage={this.state.currentPage} totalPages={this.state.totalPages} onPageChange={this.handlePageChange} />     
          </div> */}
      </div>
    );
  }
}
