import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import {  Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import "../../common/styles/common.css";
import { LinkContainer } from 'react-router-bootstrap';
import "./home.css";
import "./Profile.css";
import Products from './Products';

interface ProfilePageProps {
  isAuthenticated: boolean;
}

export interface User {
  id: string;
  userId: string;
  customStoryCreationFreeLimitCount: number;
  xrReaderFreeLimitCount: number;
  customStoryCreationSubscriptionId: string;
  xrReaderSubscriptionId: string;
  createdAt: number;
  isCustomStoryCreationCancelled: boolean;
  isXrReaderCancelled: boolean;
  stripeCustomerId: string;
}

interface ProfilePageState {
  // loading: boolean;
  email: string;
  createdAt: number,
  billingInfo: string,
  user: User | undefined,
}

export default class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
  constructor(props : ProfilePageProps) {
    super(props);

    this.state = {
      email: '',
      createdAt: 1,
      billingInfo: '',
      user: undefined
    };
  }

  async componentDidMount() {    
    try {
      const userInfo = await Auth.currentUserInfo();
      var email = userInfo.attributes.email;
      console.log("User Email" + email);

      const user = await this.fetchUserInfo(email);
      this.setState({email: email, user: user});
      this.forceUpdate();
    } catch (e) {
      alert(e);
    }
  }
  
  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
  }  

  onCreatePortal = async () => {
    const options = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': "application/json; charset=UTF-8",
        'x-api-key': 'K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ'
      }
    };

    const res = await axios.get("https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/checkout/create-portal-session/for-user/" + this.state.email, options);
    window.open(res.data, '_blank', 'noreferrer');
  }
  
  async fetchUserInfo(email : string) {
    try {
      return await API.get("checkout", "/users/" + email, {headers: {
        'x-api-key': 'K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ'
      }});

    } catch (error) {
      console.log('Error fetching user info:', error);
    }
  }

  onCheckout = async (lookupKey : string) => {
    const options = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': "application/json; charset=UTF-8",
        'x-api-key': 'K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ'
      }
    };

    const res = await axios.get(
      "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/checkout/create-checkout-session/price_1Mzsn7AOaTtYPnzzAV2Q5m8R/for-user/"
      + this.state.email, options);
    window.open(res.data, '_blank', 'noreferrer');
  }

  render() {

    if (!this.props.isAuthenticated) {
      return <Redirect to='/' />
    }

    return (
      <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {this.state.email && 
          <div className="text-center text-white-small white-box no-margin-top">
            <h4>{`Hello ${this.state.email}!`}</h4>
          </div>
        }
        <div>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <h3 className='text-white-header'>Profile</h3>
              <hr />
            </div>
            <div className='col-md-3'></div>
          </div>
          <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-6'>
              <div className="profile-detail text-white-small">
                <p>Email : {this.state.email}</p>
                {this.state.user !== undefined && <p className='text-white-small'>Created At : {this.getPrettyDate(this.state.user?.createdAt)}</p>}
                <LinkContainer to="/passwordReset">
                  <a href="/passwordReset">Password Reset</a>                
                </LinkContainer>
              </div>
              <div >
              <br></br>
              <br></br>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <br></br>
                <h3 className='text-white-header' style={{ marginRight: '10px' }}>Billing</h3>
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;                
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;                
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;                
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                {
                  ((this.state.user?.customStoryCreationSubscriptionId !== null && this.state.user?.isCustomStoryCreationCancelled === false))
                  &&
                  <Button
                    bsSize="large"
                    onClick={() => this.onCreatePortal()}>
                    Manage Subscription
                  </Button>
                }
              </div>
              <hr />
            </div>
            <br></br>
              <div className="billing-information text-white-small">
                {(this.state.user?.customStoryCreationSubscriptionId === null || this.state.user?.isCustomStoryCreationCancelled === true) &&  <p>Free Custom Story Creations Left: {this.state.user?.customStoryCreationFreeLimitCount}</p>}
                {(this.state.user?.customStoryCreationSubscriptionId === null || this.state.user?.isCustomStoryCreationCancelled === true) &&  <p>XR Reader Custom File Imports Left: {this.state.user?.xrReaderFreeLimitCount}</p>}
                {(this.state.user?.customStoryCreationSubscriptionId !== null && this.state.user?.isCustomStoryCreationCancelled === false) && <p>Stripe Subscription ID: {this.state.user?.customStoryCreationSubscriptionId}</p>}
                {(this.state.user?.customStoryCreationSubscriptionId !== null && this.state.user?.isCustomStoryCreationCancelled === false) && <p>Stripe Subscription State: {this.state.user?.isCustomStoryCreationCancelled ? "Cancelled" : "Active"}</p>}
                {/* {(this.state.user?.customStoryCreationSubscriptionId === null || this.state.user?.isCustomStoryCreationCancelled === true) && 
                  <p>
                    Get unlimited story creation and more for only $6.99/month! Cancel anytime if its not you're cup of tea.
                    <Button
                      bsSize="medium"
                      onClick={() => this.onCheckout("price_1MtuAuAOaTtYPnzzzPC3eC8N")}>
                      Purchase Premium Plan
                    </Button>
                  </p>
                } */}

              </div>
            </div>
            <div className='col-md-3'></div>

          </div>
        </div>
        
        <div>
        {(this.state.user?.customStoryCreationSubscriptionId === null || this.state.user?.isCustomStoryCreationCancelled === true) && 
          <div>
            <br></br>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <h3 className='text-white-header'>Available Subscriptions</h3>
              <hr />
              <br></br>
              <p className='text-white-small'>To support the development of this product and gain access to unlimited story creation and more, please consider subscribing. 
              Our AI models are costly to maintain, but your support will allow us to continue improving our service and providing you with the best possible experience.</p>
              <br></br>
              <br></br>
            </div>
            <div className='col-md-3'></div>
            <Products isAuthenticated={true} email={this.state.email}></Products>
          </div>
        }

        </div>
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  }
}