import React, { Component } from "react";
import "../../common/hero/hero.css";
import "../signup/home.css";
import { Auth, API } from "aws-amplify";
import CustomStoryImport from "./customStoryImport/CustomStoryImport";
import "./customStoryImport/customStoryImport.css";
import { Redirect } from "react-router-dom";
import "../../common/styles/common.css";
import { Story } from "./CustomStories";

interface CustomStoriesImportWrapProps {}

export interface PaginatedStoriesResponse {
  stories: Story[];
  totalCount: number;
}

export interface Metadata {
  bookName: string;
  author: string;
  bookCoverS3Url: string;
}

interface PastDownloadsState {
  userInfo: any; // FIXME
  isLoading: boolean;
  isStoryBeingGenerated: boolean;
  redirectToPublicStories: boolean;
  stories: Story[];
  currentPage: number;
  totalPages: number;
  email:string;
}

export default class CustomStoriesImportWrap extends Component<CustomStoriesImportWrapProps, PastDownloadsState> {
  constructor(props: CustomStoriesImportWrapProps) {
    super(props);

    this.state = {
      userInfo: null,
      isStoryBeingGenerated : false,
      isLoading: true,
      redirectToPublicStories: false,
      stories: [],
      currentPage: 1,
      totalPages: 1,
      email:''
    };
  }

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();
    if (userInfo === null) {
      this.setState({ 
        isLoading: false,
     });
      return;
    }
    this.setState({ userInfo: userInfo, email: userInfo.attributes.email });    
    try {
      const paginatedStoryResponse = await this.listStories(1);
      this.setState({ 
        stories: paginatedStoryResponse.stories,
        isLoading: false,
        totalPages: Math.ceil(paginatedStoryResponse.totalCount / 3),
        currentPage: 1
     });

    } catch (e) {
      alert(e);
    }
  }
  
  userIsAuthenticated = () => {
    return this.state.email !== '';
  }

  listStories= (pageToFetch: number) =>  {
    var email = this.state.email;    
    if (email === null) {
      return;
    }
    return API.get("story", "/"+ email + "/paginated?page=" + pageToFetch, null);
  }

  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
  }  

  goToPublicStories = async () => {
    this.setState({redirectToPublicStories: true});
  }

  handlePageChange = async (page: number) => {
    console.log(page);
    this.setState({currentPage: page});
    const paginatedStoryResponse = await this.listStories(page);
    this.setState({stories: paginatedStoryResponse.stories});
    this.forceUpdate();
  };

  listPublicStories= (pageToFetch: number) =>  {
    return API.get("story", "/public/paginated?page=" + pageToFetch, null);
  }

  render() {
    if (this.state.redirectToPublicStories === true) {
      return <Redirect to='/publicStories' />;
    }
    
    return (
      <div className="Category">
        <br></br>
        <br></br>
        <div className="row white-box">
            <br></br>
            <br></br>
              {/* <h1 className="text-white-customheader text-center">Custom Story Creator Playground</h1> */}
              <h1 className="text-white-header" style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '30px',
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                  // textTransform: 'uppercase',
                  textAlign: 'center',
                  // backgroundColor: 'rgba(44, 62, 80, 0.8)',
                  borderRadius: '10px',
                  padding: '15px',
                  color: '#ffffff',
                  // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}>
              Custom Story Creator Playground
            </h1>
              <div>
                <CustomStoryImport></CustomStoryImport>
            </div>
            <br></br>
            <br></br>
            <br></br>

        </div>  
        {/* <div>
          {this.state.stories.length != 0 && 
            <div>
              <div className="white-box">
                  <h1 className="text-white-header text-center">Previously Created Stories</h1>
                  <div className="row display-fle new">

                  {!this.state.isLoading && this.state.stories && this.state.stories
                    .sort((file1, file2) => file2.createdAt - file1.createdAt)
                    .map(file => 
                    <div  key={file.id}>
                    <CustomStoriesRow email={this.state.email} story={file} key={file.id} progressBar={-99} isXrReaderView={false} isCustomStyle={false}/>
                  </div>)
                  }
                </div>
              </div >  
              <div className="pagination-style">
                <Pagination currentPage={this.state.currentPage} totalPages={this.state.totalPages} onPageChange={this.handlePageChange} />     
              </div>
            </div>
          }
      </div> */}
      </div>
    );
  }
}
