import React from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';

type GenreSelectModalProps = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void,
  genre: string,
  onGenreChanged: (genreId: string) => void,
  genreValid: string,
  images: { id: string, name: string, src: string }[],
};

const GenreSelectModal: React.FC<GenreSelectModalProps> = ({ isOpen, onClose, onSubmit, genre, onGenreChanged, genreValid, images }) => {
    return (
        <Modal show={isOpen} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select a genre</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup controlId="genre">
              <Row>
                {genre === 'null' 
                  ? <p className="text-white-small text-center">Please select a genre</p> 
                  : <p className="text-white-small text-center">Selected Genre : {genre === '4' ? 'Anime' : 'Comic Book'}</p>}
                {images.map((image, index) => (
                  <Col key={index} xs={6} sm={6} md={6} lg={6}>
                    <h5 className="text-center">{image.name}</h5>
                    <img
                      src={image.src}
                      alt={image.name}
                      className={`img-responsive genre-image ${genre === image.id ? "selected" : ""}`}
                      onClick={() => onGenreChanged(image.id)}
                    />
                  </Col>
                ))}
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button className="secondary" onClick={onClose}>
              Close
            </Button>
            <Button className="primary" onClick={onSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      );
}

export default GenreSelectModal;