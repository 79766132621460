import React, { useState, useEffect } from 'react';
import { Modal, Grid, Row, Col, Image } from 'react-bootstrap';
import { API } from 'aws-amplify';
import Pagination from '../Pagination';
import './CharacterSelectionModal.css'; // Import the new CSS file

interface CharacterSelectionModalProps {
  show: boolean;
  genreFilter: string;
  isPublicCharactersz: boolean;
  emailz: string;
  onClose: () => void;
  onSelect: (character: Character) => void;
}

export interface PaginatedCharacterReponse {
    charactersDbWithImages: Character[];
    totalCount: number;
}


//todo: also fetch your own characters to update backend to have images
export interface Character {
    imageUrl: string;
    createdAt: number;
    id: string;
    genre: string;
    userId: string;
    name: string;
    isPrivate: boolean;
    characterBackgroud: string;
}


const CharacterSelectionModal: React.FC<CharacterSelectionModalProps> = ({ show, onClose, onSelect, genreFilter, isPublicCharactersz, emailz}) => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [totalPages, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isPublicCharacters, setIsPublicCharacters] = useState<boolean>(isPublicCharactersz);
  const [email, setEmail] = useState<string>(emailz);

  useEffect(() => {
    if (show) {
        setIsPublicCharacters(isPublicCharactersz);
        setEmail(emailz);        
        fetchPublicCharacters(1, emailz).then((data) => {
        const filteredCharacters = data.charactersDbWithImages.filter((character : Character) => character.genre === genreFilter);
        setCharacters(filteredCharacters);
        setTotalPage(Math.ceil(data.totalCount / 3));
        setCurrentPage(1);

      });
    }
  }, [show]);

  const handleCharacterSelect = (character: Character) => {
    onSelect(character);
    onClose();
  };

  
  const fetchPublicCharacters= (pageToFetch: number, email: string) =>  {
    if (!isPublicCharacters) {
        return fetchPrivateCharacters(pageToFetch, email);
    }
    return API.get("characters", "/genre/" + genreFilter +"/public/" + pageToFetch, null);
  }

    
  const fetchPrivateCharacters= (pageToFetch: number, email :string) =>  {
    return API.get("characters", "/user/" + email + "/genre/" + genreFilter +"/page/" + pageToFetch, null);
  }

  const handlePageChange = async (page: number) => {
    console.log(page);
    setCurrentPage(page);

    const paginatedStoryResponse = await fetchPublicCharacters(page, email);
    setCharacters(paginatedStoryResponse.charactersDbWithImages);
    // forceUpdate();
  };


  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select a Character</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid>
          {characters.map((character) => (
                      <Row>

              <Col key={character.id} xs={12} sm={6} md={6} lg={6}>
                <div className="character-container">
                  <Image
                    className="character-image"
                    src={character.imageUrl}
                    alt={character.name}
                    thumbnail
                    onClick={() => handleCharacterSelect(character)}
                  />
                  <p className="character-name">{character.name}</p>
                </div>
              </Col>
              </Row>

            ))}
          {/* <Row>
            {characters.splice(0, 4).map((character) => (
              <Col key={character.id} xs={7} sm={5} md={4} lg={3}>
                <div className="character-container">
                    <Image
                    className="character-image" // Add the new CSS class
                    src={character.imageUrl}
                    alt={character.name}
                    thumbnail
                    onClick={() => handleCharacterSelect(character)}
                    />
                    <p className="character-name">{character.name}</p>
                    <p className="character-name">{character.characterBackgroud}</p>
                </div>
              </Col>
            ))}
          </Row> */}
        </Grid>
      </Modal.Body>
      <Modal.Footer>
            <div className="pagination-style">
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />     
            </div>
        {/* <Button onClick={onClose}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default CharacterSelectionModal;
