import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import {  Button, Col, Grid, Panel, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import "../../common/styles/common.css";
import "./home.css";
import "./Profile.css";

interface ProductsProps {
  isAuthenticated: boolean;
  email: string;
}

interface ProductsState {
  email: string;
}

const products = [
  {
    title: 'Basic',
    price: 'Free',
    features: [
      'A limit of 3 custom story creation requests',
      'A limit of 3 XR Reader custom file imports',
      'Limited story updates',
      'View public stories',
      'Default custom story genres',
      'Intended as a free trial',
    ],
  },
  {
    title: 'Pro (cancel anytime)',
    price: '$6.99/month',
    features: [
      'Access to all basic version features',
      'Unlimited story creation',
      'Unlimited XR Reader custom file imports',
      'Unlimited panel additions',
      'Access to upcoming premium features: panel updates, deletes and re-ordering',
      'Access to upcoming genres (i.e. Cyber Punk, Fantasy, etc.)',
    ],
  },
];



export default class Products extends React.Component<ProductsProps, ProductsState> {
  constructor(props : ProductsProps) {
    super(props);

    this.state = {
      email: ''
    };
  }

  async componentDidMount() {    
    try {
      this.setState({email: this.props.email});
      this.forceUpdate();
    } catch (e) {
      alert(e);
    }
  }

  
  async fetchUserInfo(email : string) {
    try {
      return await API.get("checkout", "/users/" + email, null);
    } catch (error) {
      console.log('Error fetching user info:', error);
    }
  }

  onCheckout = async (lookupKey : string) => {
    const options = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': "application/json; charset=UTF-8",
        'x-api-key': 'K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ'
      }
    };

    const res = await axios.get(
      "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/checkout/create-checkout-session/price_1Mzsn7AOaTtYPnzzAV2Q5m8R/for-user/"
      + this.state.email, options);
    window.open(res.data, '_blank', 'noreferrer');
  }

  render() {

    if (!this.props.isAuthenticated) {
      return <Redirect to='/' />
    }

    return (
      <div>
        <Grid>
          <Row>
            <br></br>
            {products.map((product, index) => (
              <Col key={index} xs={12} sm={6}>
                <Panel className="product-card">
                  <Panel.Heading>
                    <h3 className='text-center'>{product.title}</h3>
                    <br></br>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="product-price">{product.price}</div>
                    <ul>
                      {product.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                      ))}
                    </ul>
                  </Panel.Body>
                  {index !== 0 && 
                      <Panel.Footer>
                        <Button
                          block
                          bsSize="large"
                          onClick={() => this.onCheckout("price_1Mzsn7AOaTtYPnzzAV2Q5m8R")}>
                          Purchase Premium Plan
                        </Button>
                    </Panel.Footer>
                  }
                  {index === 0 && 
                      <Panel.Footer>
                        <Button
                          block
                          bsSize="large"
                          disabled={true}
                          onClick={() => this.onCheckout("price_1Mzsn7AOaTtYPnzzAV2Q5m8R")}>
                          Current Subscription
                        </Button>
                    </Panel.Footer>
                  }
                </Panel>
              </Col>
            ))}
          </Row>
        </Grid>
      </div>
    );
  }
}