import React from "react";
import { Redirect } from 'react-router';
import { FormGroup, FormControl, ControlLabel, Button, Glyphicon, HelpBlock, ProgressBar, Alert, Checkbox, Col, Row, Grid, Modal, Dropdown, DropdownButton, MenuItem} from "react-bootstrap";
import "./customStoryImport.css";
import { API, Auth } from "aws-amplify";
import { CustomStoriesRow } from "../CustomStoriesRow";
import { Story } from "../CustomStories";
import CharacterSelectionModal, { Character } from "./CharacterSelectionModal";
import animeImage from "../../../images/animeImage.jpeg";
import cyberAnime from "../../../images/cyberAnime.jpeg";
import mangaImage from "../../../images/manga.jpeg";
import childrens from "../../../images/newChildrensImage.jpeg";
import comicImage from "../../../images/comicImage.jpeg";
import cyberComicBook from "../../../images/cyberComicBook.jpeg";
import GenreSelectModal from "./GenreSelectModal";

interface CustomStoryImportProps {
}

interface CustomFileProps {
  loading: boolean;
  redirect: boolean;
  termsAndServiceAccepted: boolean;
  useGptFourSelected: boolean;
  customStorySelected: string;
  isPublicCharacters: boolean;
  prompt: string;
  genre: string;
  latestStoryId: string;
  latestStory: Story | undefined;
  progressBar: number;
  numOfFetchFailures: number;
  email: string;
  user: any;
  genreValid: "success" | "error" | "warning" | undefined;
  promptValid: "success" | "error" | "warning" | undefined;
  selectedCharacterValid: "success" | "error" | "warning" | undefined;
  customStoryValid: "success" | "error" | "warning" | undefined;
  showModal: boolean;
  advancedSettingsClicked: boolean;
  selectedCharacter: Character | null;
  isModalOpen : boolean;
  panelCount: number;
  selectedLanguage: string
}

export interface S3ImportUrlResponse {
  s3Url: string;
}


const examplePrompts: string[] = [
  "A reluctant student discovers they're the reincarnation of a legendary warrior, destined to defeat an ancient evil threatening their world",
  "A mysterious transfer student hides a supernatural secret, drawing classmates into a hidden world of magic and intrigue",
  "In a school for aspiring ninjas, a young outcast fights to prove their worth and earn the respect of their peers",
  "A mecha pilot must join forces with rival factions to protect Earth from an interdimensional threat",
  "An everyday teenager stumbles upon a magical artifact, transforming them into a powerful hero tasked with defending their city from monstrous villains",
  "A high school club of supernatural investigators unravels a series of mystical occurrences, revealing a centuries-old conflict",
  "In a world where spirits and humans coexist, a spirited apprentice exorcist battles to maintain harmony between both realms",
  "A struggling high school student discovers they can leap through time, using their newfound power to fix past mistakes and change their future",
  "A group of strangers with psychic abilities must band together to prevent an apocalyptic event orchestrated by a mysterious organization",
  "After being transported to a fantastical realm, a young adventurer assembles a ragtag group of allies to overthrow an oppressive empire",
  "A timid teenager befriends a magical creature that grants them the power to transform into a courageous warrior, standing up against evil forces",
  "In a dystopian future, a skilled bounty hunter navigates a treacherous cyberpunk city to capture elusive criminals and unravel a dark conspiracy",
  "A determined young chef enters a cutthroat culinary competition, seeking to master the art of cooking and claim the title of world's greatest chef",
  "When a mythical creature awakens from a centuries-long slumber, a group of heroes must retrace the steps of an ancient prophecy to restore balance",
  "In a post-apocalyptic world, a group of survivors must outwit hostile factions and mutated creatures in search of a rumored safe haven",
  "A young inventor's creation accidentally triggers a time paradox, hurling them into a whimsical past and an unpredictable future",
  "In a reality where dreams can be harvested and traded, a skilled 'dream thief' faces moral dilemmas and dark forces in the lucrative dream trade",
  "An ambitious explorer discovers an ancient map leading to a hidden world, launching a treacherous journey filled with wonders and dangers alike",
  "In an alternative 19th century, a rebellious mechanic must repair a complex steam-powered machine to save her city from an energy crisis",
  "After receiving a distress signal from an abandoned spaceship, a crew of astronauts stumbles upon a terrifying extraterrestrial creature",
  "A pacifist grows up in a warrior society, challenging their culture's norms in an attempt to bring peace to their war-torn world",
  "In a world where music grants magical abilities, a prodigy musician uncovers a melody with the power to reshape their society",
  "A child prodigy detective, equipped with a mysterious AI gadget, solves complex cases that baffle even the seasoned investigators",
  "After a meteor strike grants superpowers to a small town's inhabitants, a reluctant hero rises to prevent the powers from being exploited",
  "A young scribe deciphers a mystical script promising immense power, setting off a dangerous race against a ruthless sorcerer",
  "Trapped in a haunted mansion, a group of friends must solve eerie puzzles and confront restless spirits to find their way out",
  "In a realm where reality is shaped by collective beliefs, a skeptical outsider strives to uncover the truth behind the realm's enigmatic existence"
];

const languages = [
  "Afrikaans", "Albanian", "Amharic", "Arabic", "Azerbaijani", "Basque", "Belarusian", "Bengali", 
  "Bosnian", "Bulgarian", "Burmese", "Cebuano", "Chichewa", "Corsican", "Croatian", "Czech", 
  "Danish", "Dutch", "English", "Estonian", "Filipino", "Finnish", "French", "Frisian", "Galician", 
  "Georgian", "German", "Greek", "Haitian Creole", "Hausa", "Hebrew", "Hindi", "Hmong", "Hungarian", 
  "Icelandic", "Igbo", "Indonesian", "Italian", "Japanese", "Javanese", "Kannada", "Kazakh", "Korean", 
  "Kurdish", "Kyrgyz", "Lao", "Latin", "Latvian", "Lithuanian", "Luxembourgish", "Macedonian", "Malagasy", 
  "Malay", "Malayalam", "Maltese", "Maori", "Marathi", "Mongolian", "Myanmar (Burmese)", "Nepali", 
  "Norwegian", "Pashto", "Persian", "Polish", "Portuguese", "Punjabi", "Romanian", "Russian", "Samoan", 
  "Serbian", "Sinhala", "Slovak", "Slovenian", "Somali", "Spanish", "Sundanese", "Swahili", "Swedish", 
  "Tajik", "Tamil", "Telugu", "Thai", "Turkish", "Ukrainian", "Urdu", "Uzbek", "Vietnamese", "Welsh", 
  "Xhosa", "Yiddish", "Yoruba", "Zulu"
];

export default class CustomStoryImport extends React.Component<CustomStoryImportProps, CustomFileProps> {

  
  constructor(props: CustomStoryImportProps) {
    super(props);
    this.handleSliderChange = this.handleSliderChange.bind(this);

    this.state = {
      loading: false,
      progressBar: -1,
      redirect: false,
      prompt: '',
      latestStoryId: '',
      latestStory: undefined,
      genre: "4",
      genreValid: 'success',
      termsAndServiceAccepted: false,
      isPublicCharacters: false,
      useGptFourSelected: false,
      advancedSettingsClicked: false,
      customStoryValid: undefined,
      customStorySelected: 'full',
      selectedCharacterValid: undefined,
      promptValid: undefined,
      email:'',
      numOfFetchFailures: 0,
      user: undefined,
      showModal: false,
      selectedCharacter: null,
      isModalOpen: false,
      panelCount: 12,
      selectedLanguage: "English"
    };
  }

  images = [
    { id: '4', name: 'Anime', src: animeImage },
    { id: '2', name: 'Comic Book', src: comicImage },
    { id: '3', name: 'Manga', src: mangaImage },
    { id: '1', name: 'Childrens Book', src: childrens },
    { id: '9', name: 'CyberPunk Anime', src: cyberAnime },
    { id: '10', name: 'CyberPunk Comic', src: cyberComicBook },
    // { id: '11', name: 'Simple Anime', src: simpleAnime },
    // { id: '12', name: 'Simple Manga', src: simpleManga },
  ];
  
  async componentDidMount() {    
    try {
      const userInfo = await Auth.currentUserInfo();
      var email = userInfo.attributes.email;
      console.log("User Email" + email);

      const user = await this.fetchUserInfo(email);
      this.setState({email: email, user: user});
      this.forceUpdate();
    } catch (e) {
      alert(e);
    }
  }

  async fetchUserInfo(email : string) {
    try {
      return await API.get("checkout", "/users/" + email, {headers: {
        'x-api-key': 'K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ'
      }});
    } catch (error) {
      console.log('Error fetching user info:', error);
    }
  }

  findNameById = (id: string) => {
    const image = this.images.find((img) => img.id === id);
    return image ? image.name : null;
  };

  findImageUrlById = (id: string) => {
    const image = this.images.find((img) => img.id === id);
    return image ? image.src : animeImage;
  };

  onChracterModalClicked = (isPublicChracters: boolean) => {
    this.setState({ isPublicCharacters: isPublicChracters, showModal: true });
  };

  closeModal = () => {
    this.setState({showModal: false, isPublicCharacters: false});
    // this.setState({ showModal: false });
  };

  handleCharacterSelect = (character: Character) => {
    console.log(character.name);
    this.setState({ selectedCharacter: character, selectedCharacterValid: 'success' });
  };

  getGenreFilter = () => {
    switch (this.state.genre) {
      case "1":
        return "CHILDRENS_BOOK";
      case "2":
        return "COMIC_BOOK";
      case "3":
        return "MANGA";
      case "4":
        return "ANIME";
      default:
        return "ANIME";
    }
  };

  onPromptChanged = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      prompt: target.value,
      promptValid: target.value.length > 300 ? 'error' : 'success'
    });
  }

  handleSelect = (language: string) => {
    console.log("FDFGDFdg");
    this.setState({
      selectedLanguage: language
    });
  }


  // handleSelect = (event: React.FormEvent<FormControl>) => {
  //   console.log("FDFGDFdg");
  //   const target = event.target as HTMLInputElement;
  //   this.setState({
  //     selectedLanguage: target.value
  //   });
  // }

  handleSliderChange= (event: React.FormEvent<FormControl>) =>  {
    const target = event.target as HTMLInputElement;
    this.setState({
        panelCount: parseInt(target.value)
    });
}

  onPromptAutomaticallyChanged = (examplePrompt: string) => {
    this.setState({
      prompt: examplePrompt,
      promptValid: 'success' 
    });
  }

  onGenreChanged = (genreString: string) => {
    this.setState({
      genre: genreString,
      genreValid : 'success'
    });
  }

  onGiveMePromptClicked() {
    console.log("randomPrompt");
    const randomPrompt = this.getRandomPrompt();
    console.log(randomPrompt);
    this.onPromptAutomaticallyChanged(randomPrompt);
  }


  getRandomPrompt() {
    const randomIndex = Math.floor(Math.random() * examplePrompts.length);
    return examplePrompts[randomIndex];
  }

  onTermsAndServiceChanged = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      termsAndServiceAccepted: !this.state.termsAndServiceAccepted
    });
  }

  useGpt4Selected = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      useGptFourSelected: !this.state.useGptFourSelected
    });
  }

  onAdvancedSettingsClicked = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      advancedSettingsClicked: !this.state.advancedSettingsClicked
    });
  }
  
  handleClose = () => {
    this.setState({isModalOpen: false})
    // return false;
  };

  handleShow = () => {
    this.setState({isModalOpen: true});
    return true;
  };

  customStorySelected = (event: React.FormEvent<FormControl>)  => {
    const target = event.target as HTMLInputElement;
    this.setState({
      customStorySelected: target.value,
      customStoryValid: 'success'
    });
  }

  delay = (ms : number) => new Promise(res => setTimeout(res, ms));

  onSubmitFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loading: true });

    var email = this.state.email;
    if (email == null) {
      this.setState({
        promptValid: 'error'
      });
      this.setState({progressBar: -200, redirect: false});
      this.resetComponent();
      return;
    }
    var y: number = +this.state.genre;

    var useGpt4 = true;
    if (this.state.genre == "2" || this.state.genre == "4" || this.state.genre == "3") {
      useGpt4 = false;
    }

    try {
      var storyId = await API.post("story", "/generate", {
          body: {
              Prompt: this.state.prompt,
              CustomBookGenreType: y,        
              UserId: email,
              UseGptFour: useGpt4,
              IsFrontEndWebsite: true,
              PanelCount: this.state.panelCount,
              CharacterId: this.state.selectedCharacter !== null ? this.state.selectedCharacter.id : null,
              Language: this.state.selectedLanguage
          }
      });
      console.log(storyId);
  } catch (error) {
      if (error.response && error.response.status === 400) { // Assuming error.response structure. Adjust as per your actual error object.
          console.log("violation");
          alert("Blocked Request. This prompt violates are policy."); // Adjust to match the structure of your error object.
          this.resetComponent();
          return;
      } else {
          alert("Failed to process request to the server. Please try again later.");
          this.resetComponent();
          return;
      }
  }

  
    // var storyId = await API.post("story", "/generate", {
    //   body: {
    //     Prompt: this.state.prompt,
    //     CustomBookGenreType: y,        
    //     UserId: email,
    //     UseGptFour: useGpt4,
    //     IsFrontEndWebsite: true,
    //     PanelCount: this.state.panelCount,
    //     CharacterId: this.state.selectedCharacter !== null ? this.state.selectedCharacter.id : null,
    //     Language: this.state.selectedLanguage
    //   }
    // });
    console.log(storyId);

    if (storyId.includes("Violations")) {
      console.log("Violations" + storyId);
      alert("Prompt violates our policy. " + storyId);
      return;
    }

    this.setState({latestStoryId : storyId})

    await this.getLatestStory(storyId, 0, 0);
  }

  async findLatestStoryRecursive() {
    setTimeout(await this.getLatestStory(this.state.latestStoryId, this.state.progressBar, 0), 5000);
  }

  getLatestStory = async (storyId: string, progressBar: number, errorCount: number) => {
    var email = this.state.email;   
    if (email == null) {
      this.setState({progressBar: -200, redirect: false});
      this.resetComponent();
      return;
    }
    try {
      var latestStory = await API.get("story", "/" + storyId, null);
      this.setState({ 
        latestStory: latestStory,
        redirect : true,
        progressBar: progressBar
      });

      if (latestStory.status === 0) {
        await this.delay(5000);


        var progressUpdateCount = (20 / this.state.panelCount) * 2;
        var progressBarCurrentValue = this.state.progressBar + progressUpdateCount;
        console.log(progressBarCurrentValue.toString());
        this.setState({progressBar: progressBarCurrentValue});
        this.forceUpdate();
        this.getLatestStory(storyId, progressBarCurrentValue, errorCount);
      } else if (latestStory.status === 1) {
        alert("Story Generation Failed. We will automatically try to re-generate this story, so please check the 'Past Created Stories' section in a few minutes");
        this.setState({progressBar: -100, redirect: false});
        this.resetComponent();
      } else if (latestStory.status === 2) {
        this.setState({latestStory: latestStory, progressBar: 100});
        this.forceUpdate();
      } 
     return latestStory;
    } catch (e) {
      console.log(e);
      if (errorCount < 3) {
        this.getLatestStory(storyId, progressBar, errorCount + 1);
      } else {
        this.setState({ progressBar: -100, redirect: false });
        this.resetComponent();
      }
      return;
    }
  }

  resetComponent = async () => {
    this.setState({
      loading: false,
      redirect: false,
      prompt: '',
      latestStoryId: '',
      latestStory: undefined,
      genre: "null",
      genreValid: undefined,
      promptValid: undefined,
      termsAndServiceAccepted: false
    });
  }

  publishStory = async () => {
    try {
      await API.post("story", "/" + this.state.latestStoryId + "/publish", null);
    } catch (e) {
      console.log(e);  
      
      return;
    }
  }

  freeLimitIsExceeded = () => {
    const user = this.state.user;
    
    var customHasActiveSubscription = 
      user.customStoryCreationSubscriptionId !== null 
        && user.isCustomStoryCreationCancelled === false;

    if (customHasActiveSubscription) {
      return false;
    }

    return user.customStoryCreationFreeLimitCount <= 0;
  }

  createNewStory = async () => {
    this.setState({redirect: false});
  }

  // Get username and url/file to be sent to backend? and what other parameters
  render() {
    if (this.state.redirect) return (           
      <div>
        <div className="row">
          <br></br>
          <div className="col-sm-4 col-md-4"></div>
          <div className="col-sm-4 col-md-4">
            {this.state.progressBar !== -1 && this.state.progressBar !== 100 &&
              <div>
                {/* <Glyphicon glyph="refresh" className="spinning" /> */}
                <ProgressBar active now={this.state.progressBar} bsSize="small"/>
                <h3 className="text-font">Generating your story, kindly give us a few minutes to bring it to life...</h3>
              </div> 
            }   
          </div>
          <div className="col-sm-4 col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-md-4"></div>
            <CustomStoriesRow email={this.state.email} story={this.state.latestStory} progressBar={this.state.progressBar} key={this.state.progressBar} isXrReaderView={false} isCustomStyle={false}/>
          <div className="col-sm-4 col-md-4"></div>
        </div>
        <div>
        <div className="row">
          {this.state.progressBar === 100 && 
            <div>
              <div className="col-sm-4 col-md-4">
              </div>
              <div className="col-sm-4 col-md-4">
                <h1 className="text-white text-center"> Want to try another prompt?</h1>
                <br></br>
              </div>
              <div className="col-sm-4 col-md-4">
              </div>
              <br></br>
              <div className="col-sm-5 col-md-5">
              </div>
              <div className="col-sm-2 col-md-2">
                <br></br>
                <Button
                  bsSize="large"
                  block
                  disabled={!this.state.latestStory?.isPrivate}
                  onClick={this.resetComponent}>
                  Create a New Story
                </Button>
              </div>
              <div className="col-sm-5 col-md-5">
              </div>
              <br></br>
            </div>
          }   
        </div>
      </div>
      <br></br>
      {/* <hr></hr> */}
      </div>
    )

    return (
      <div className="Login">
        <br></br>
        {this.state.progressBar === -100 || this.state.progressBar === -101 || this.state.progressBar === -102 && 
          <Alert bsStyle="error">
            <strong>Sorry about that!</strong> 
              Looks like your story either failed, or is taking longer then expected.
             We will retry this story, so make sure to view the past stories section in a few minutes.
          </Alert>
        }
        {this.state.progressBar === -200 && 
          <Alert bsStyle="error">
            <strong>Sorry about that!</strong> Looks like your not signed in. Please sign in to continue.
            <Redirect to='/login' />
          </Alert>
        }
        <form onSubmit={this.onSubmitFile}>  
        <FormGroup controlId="prompt" validationState={this.state.promptValid}>
          <Grid>
            <Row>
              <Col xs={12} sm={8}>
              <ControlLabel>Prompt</ControlLabel>
                <FormControl
                  name="prompt"
                  type="text"
                  bsSize="large"
                  value={this.state.prompt}
                  onChange={this.onPromptChanged}
                />
                <FormControl.Feedback />
                <HelpBlock>
                  <p className="text-white-small">Unleash your imagination and enter any story prompt</p>
                  <p className="text-white-small"><small>(Pornographic content is strictly forbidden)</small></p>
                </HelpBlock>
              </Col>
            </Row>
            <Row>

              <Col xs={6} sm={3} >
                <FormGroup controlId="selectedLanguage">
                  <DropdownButton bsSize="medium" title={this.state.selectedLanguage} id="dropdown-basic-1" 
                    onClick={(e) => e.stopPropagation()} 
                    style={{overflow: 'auto', maxHeight: '200px'}}>
                    {languages.map((language, index) => (
                      <MenuItem eventKey={language} key={index} onClick={() => this.handleSelect(language)}>{language}</MenuItem>
                    ))}
                  </DropdownButton>
                </FormGroup>
                {/* <Button
                    bsSize="small"
                    onClick={this.onAdvancedSettingsClicked}
                  >
                  Advanced Settings {this.state.advancedSettingsClicked ? <Glyphicon glyph="menu-up"></Glyphicon>:<Glyphicon glyph="menu-down" />}
                </Button> */}
              </Col>
              <Col xs={6} sm={1} mdOffset={4}>
                <Button
                  bsSize="medium"
                  onClick={() => this.onGiveMePromptClicked()}
                >
                  Suprise Me
                </Button>
              </Col>

            </Row>
          </Grid>
        </FormGroup>
        {/* {this.state.advancedSettingsClicked && */}
        <div>
          {/* <Row>
            <Col xs={12} sm={8} smOffset={3} md={6} mdOffset={3}>
              <FormGroup controlId="selectedLanguage">
                <DropdownButton bsSize="large" title={this.state.selectedLanguage} id="dropdown-basic-1" 
                  onClick={(e) => e.stopPropagation()} 
                  style={{overflow: 'auto', maxHeight: '200px'}}>
                  {languages.map((language, index) => (
                    <MenuItem eventKey={language} key={index} onClick={() => this.handleSelect(language)}>{language}</MenuItem>
                  ))}
                </DropdownButton>
              </FormGroup>
              </Col>
          </Row> */}
          <Row>
            <Col xs={12} sm={8} smOffset={3} md={6} mdOffset={3}>
              <FormGroup controlId="customStorySelected" validationState={'success'}>
                    <ControlLabel>Panel Count ({this.state.panelCount})</ControlLabel>
                    <FormControl
                        type="range"
                        min={9}
                        max={20}
                        value={this.state.panelCount}
                        onChange={this.handleSliderChange}
                    />
                    <FormControl.Feedback />
                </FormGroup>
              </Col>
          </Row>
          <Row>
            <Col xs={12} sm={8} smOffset={3} md={6} mdOffset={3}>
              <FormGroup controlId="genereSelected" validationState={'success'}>

              {/* <Button className="primary" onClick={() => this.handleShow()}>
                Select genre
              </Button> */}
              {this.state.genre === 'null' 
              ? <p className="text-white-small text-center">Please select a genre</p> 
              : 
                <div>
                  <ControlLabel>Please select a genre</ControlLabel>
                  <img src={this.findImageUrlById(this.state.genre)} className="img-responsive genre-image" alt="Custom file" onClick={() => this.handleShow()}/>
                  <br></br>
                  <p className="text-white-small text-center">Selected Genre : {this.findNameById(this.state.genre)}</p>
                </div>
              // <p className="text-white-small text-center">Selected Genre : {this.findNameById(this.state.genre)}</p>
              }


              <GenreSelectModal
                isOpen={this.state.isModalOpen}
                onClose={() => this.handleClose()}
                onSubmit={() => this.handleClose()} // Change this to your actual submit function
                genre={this.state.genre} // Your state variable for genre
                onGenreChanged={this.onGenreChanged} // Your function for updating genre
                genreValid={'success'} // Your state variable for genre validation
                images={this.images}
              />
              </FormGroup>
            </Col>
          </Row>
        <Grid>
  <br></br>
  {/* <Row>
    <Col xs={12} sm={8} smOffset={2} md={6} mdOffset={1}>
      <FormGroup controlId="characterSelection" validationState={this.state.selectedCharacterValid}>
        <ControlLabel>Character Selection</ControlLabel>
        <Row>
          <Col xs={9} sm={6} md={6} lg={6}>
            <Button bsSize="large" onClick={()=>this.onChracterModalClicked(false)} disabled={this.state.genre === 'null'} block>
              <p className="text-white-verysmall">Your Characters</p>
            </Button>
          </Col>
          <Col xs={9} sm={6} md={6} lg={6}>
            <Button bsSize="large" onClick={()=>this.onChracterModalClicked(true)} disabled={this.state.genre === 'null'} block>
              <p className="text-white-verysmall">Public Characters</p>
            </Button>
          </Col>
        </Row>
        <HelpBlock>
          {this.state.selectedCharacter === null ? 
            <p className="text-white-small">Pick any existing public or personal characters (leave blank to let AI create a new character)</p>
          : <p className="text-white-small">Selected Character : {this.state.selectedCharacter.name}</p>
          }
        </HelpBlock>
      </FormGroup>
    </Col>
  </Row> */}

    <CharacterSelectionModal
      isPublicCharactersz={this.state.isPublicCharacters}
      emailz={this.state.email}
      show={this.state.showModal}
      genreFilter={this.getGenreFilter()}
      onClose={this.closeModal}
      onSelect={this.handleCharacterSelect}
    />

    <CharacterSelectionModal
      isPublicCharactersz={true}
      emailz={this.state.email}
      show={this.state.showModal}
      genreFilter={this.getGenreFilter()}
      onClose={this.closeModal}
      onSelect={this.handleCharacterSelect}
    />
  </Grid>
        <br></br>
        </div>
        {/* } */}

<Grid>
  <Row>
    <Col xs={12} sm={8} smOffset={2} md={6} mdOffset={1}>
    <FormGroup>
        <Checkbox inline onChange={this.onTermsAndServiceChanged}>
          <p className="text-white-small">
            Please read and accept our{" "}
            <a href={"https://docs.google.com/document/d/12s5QH9kc5p8JdOfX52KbddsS1Gy31Bi9ufflpcpaFR4/edit?usp=sharing"} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </p>
        </Checkbox>
      </FormGroup>
      <Button
        block
        bsSize="large"
        type="submit"
        disabled={
          (this.state.genreValid !== 'success') || 
          (this.state.promptValid !== 'success') || 
          this.state.termsAndServiceAccepted === false ||
          this.state.genre === 'null' ||
          this.freeLimitIsExceeded()}>
        {this.state.loading && <Glyphicon glyph="refresh" className="spinning" />}Create Story
      </Button>
      {(this.state.user?.customStoryCreationSubscriptionId === null || this.state.user?.isCustomStoryCreationCancelled === true) && 
        <HelpBlock>
          <br></br>
          <p className="text-font-small text-center">
            {this.state.user?.customStoryCreationFreeLimitCount} free custom story creations left
          </p>
          <p className="text-font-small text-center">
            AI models are costly. Subscribe on the <a href="/profile">profile & billing</a> page to support us and to receive unlimited story creation
          </p>
        </HelpBlock>
      }
    </Col>
  </Row>
</Grid>
        </form>
      </div>
    );
  }
}