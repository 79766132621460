import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import Amplify from "aws-amplify";
import config from "./config";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "books",
        endpoint: config.apiGateway.API_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "cart",
        endpoint: config.apiGateway.API_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "orders",
        endpoint: config.apiGateway.API_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "search",
        endpoint: config.apiGateway.API_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "bestsellers",
        endpoint: config.apiGateway.API_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "s3fileupload",
        endpoint: "h",
        region: config.apiGateway.REGION
      },
      {
        name: "files",
        endpoint: config.fileImportApiGateway.API_URL,
        region: config.fileImportApiGateway.REGION
      },
      {
        name: "story",
        endpoint: config.storyGenerateApiGateway.API_URL,
        region: config.storyGenerateApiGateway.REGION
      },
      {
        name: "checkout",
        endpoint: config.checkoutApiGateway.API_URL,
        region: config.checkoutApiGateway.REGION
      },
      {
        name: "characters",
        endpoint: config.charactersApiGateway.API_URL,
        region: config.charactersApiGateway.REGION
      },
      {
        name: "episodes",
        endpoint: config.episodesApiGateway.API_URL,
        region: config.episodesApiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
