import React from "react";
import "../../common/styles/productRow.css";
import { API, Auth } from "aws-amplify";
import {Story} from "./CustomStories";
import "../../common/styles/gallery.css";
import { Button, Col, ControlLabel, DropdownButton, FormControl, FormGroup, Glyphicon, Grid, HelpBlock, MenuItem, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { User } from "../signup/ProfilePage";
import CustomStoryPanelButtonForm from "./CustomStoryPanelButtonForm";
import "../../common/styles/common.css";

interface CustomStoriesPanelProps {
  match: any;
}

export interface Scene {
  id: string;
  storyId: string;
  userId: string;
  sceneCount: number;
  imagePrompt: string;
  createdAt: number;
  chapterSummary: string;
  rawImageFileName: string;
  voiceFileName: string;
  text: string;
  sceneState: number;
}

interface CustomStoriesPanelState {
  storyId: string;
  story: Story | undefined;
  images: ImageDecorator[];
  scenes: Scene[];
  visible: boolean;
  currentPage: number;
  storyLoaded: boolean;
  email:string;
  loading: boolean;
  numOfFailuresCount: number;
  user: User | undefined;
  isTopBarVisible : boolean;
  prompt: string;
  promptValid: string;
  dialouge: string;
  dialougeValid: string;
  caption: string;
  captionValid: string;
  dropdownVisible : boolean;
  showUpdateButtons: boolean;
}

export interface ImageDecorator {
  src: string;
  alt?: string;
  downloadUrl?: string;
  defaultSize?: ViewerImageSize;
  voiceFileSource: string;
}

export interface ViewerImageSize {
  width: number;
  height: number;
}


export class CustomStoriesPanel extends React.Component<CustomStoriesPanelProps, CustomStoriesPanelState> {
  recursionTimeout: NodeJS.Timeout | null;

  
  constructor(props: CustomStoriesPanelProps) {
    super(props);

    console.log(this.props.match.params.id);
    
    this.state = {
      storyId: this.props.match.params.id,
      story: undefined,
      images: [],
      scenes: [],
      visible: false,
      currentPage: 0,
      storyLoaded: false,
      email: '',
      loading: false,
      numOfFailuresCount: 0,
      user: undefined,
      prompt: '',
      promptValid: 'success',
      dialouge: '',
      dialougeValid: 'success',
      caption: '',
      captionValid: 'success',
      isTopBarVisible : true,
      dropdownVisible: false,
      showUpdateButtons: false
    };

    this.recursionTimeout = null;
  }

  async componentDidMount() {
    try {
      const userInfo = await Auth.currentUserInfo();
      let story = await this.getStory(this.state.storyId);
      window.addEventListener('scroll', this.handleScroll);
      if (story.fileId !== null) {
        console.log("story releoeded" + story.fileId);
        var pageRequest = this.getPageUrls(story);
        var sceneRequest = this.getScenes(story);
        let urls = await pageRequest;    
        let sceneList = await sceneRequest;  
        sceneList = sceneList.filter((scene: Scene) => scene.sceneState !== 1 && scene.sceneState !== 2)
        let isItLoaded = true;

        if (userInfo === null || userInfo === undefined) {
          this.setState({story: story, images: urls, scenes: sceneList, storyLoaded: isItLoaded});
        } else {
          var email = userInfo.attributes.email;
          if (story.storyType === 0) {
            var userResponse = await this.fetchUserInfo(email);
            this.setState({story: story, images: urls, scenes: sceneList, storyLoaded: isItLoaded, email: email, user: userResponse});
          } else {
            this.setState({story: story, images: urls, scenes: sceneList, storyLoaded: isItLoaded, email: email, user: userResponse});
          }
        }
        this.forceUpdate();
        console.log("story done" + story.fileId);
      }
    } catch (e) {
      alert(e);
    }
    this.forceUpdate();
  }

  getStatusString = (statusNumber: number) => {
    switch(statusNumber) {
      case 0:
        return 'IN PROGRESS';
      case 1:
        return 'FAILED';
      case 2:
        return 'COMPLETE';
      default:
        return 'DELETED';
      }
  }

  onAddNewPanel = async () => {
    try {
      this.setState({loading: true});  
      if (this.state.story === undefined) {
        alert("Story is broken! Please create a new one");
        return;
      }
      // var y: number = +this.state.genre;
      var y: number = +this.state.story?.genre;
      var storyId = await API.post("story", "/update", {
        body: {
          StoryId: this.state.story?.id,
          UpdateOperation: 0,
          CustomBookGenreType: this.getGenreInt(this.state.story.genre),
          UserId: this.state.email,
          ChapterPrompt: this.state.prompt !== '' ? this.state.prompt : null,
          Dialogue: this.state.dialouge !== '' ? this.state.dialouge : null,
          Caption: this.state.caption !== '' ? this.state.caption : null
        }
      });
  
      this.getAnyPageUrlUpdates();
      console.log(storyId);
    } catch (error) {
        alert(error);
        return;
    }
  }

  replacePanel = async (scene: Scene) => {
    try {
      this.setState({loading: true});  
      if (this.state.story === undefined) {
        alert("Story is broken! Please create a new one");
        return;
      }
      var y: number = +this.state.story?.genre;
      var storyId = await API.post("story", "/update", {
        body: {
          StoryId: this.state.story?.id,
          UpdateOperation: 1,
          SceneCount: scene.sceneCount,
          CustomBookGenreType: this.getGenreInt(this.state.story.genre),
          UserId: this.state.email
        }
      });
  
      this.getAnyPageUrlUpdates();
      console.log(storyId);
    } catch (error) {
        alert(error);
        return;
    }
  }

  deletePanel = async (scene: Scene) => {
    try {
      this.setState({loading: true});  
      if (this.state.story === undefined) {
        alert("Story is broken! Please create a new one");
        return;
      }
      // var y: number = +this.state.genre;
      var y: number = +this.state.story?.genre;
      var storyId = await API.post("story", "/update", {
        body: {
          StoryId: this.state.story?.id,
          UpdateOperation: 2,
          SceneCount: scene.sceneCount,
          CustomBookGenreType: this.getGenreInt(this.state.story.genre),
          UserId: this.state.email
        }
      });
  
      this.getAnyPageUrlUpdates();
      console.log(storyId);
    } catch (error) {
        alert(error);
        return;
    }
  }

  // CLASSIC_SHORT = 0,
  // CHILDRENS_BOOK = 1,
  // COMIC_BOOK = 2,
  // MANGA = 3,
  // ANIME = 4,
  // CLASSIC_LONG = 5,
  // HISTORY = 6,
  // HOW_TO = 7,
  // MOTIVATION = 8,

  getGenreInt = (genre: string) => {
    switch(genre) {
      case "CHILDRENS_BOOK":
        return 1;
      case "CLASSIC_SHORT":
        return 0;
      case "COMIC_BOOK":
        return 2;
      case "MANGA":
        return 3;
      case "ANIME":
        return 4;
      case "CLASSIC_LONG":
        return 5;
      default:
        return 'DELETED';
      }
  }

  toggleDropdownVisibility = () => {
    this.setState((prevState) => ({
      dropdownVisible: !prevState.dropdownVisible,
    }));
  };
  

  getCoverPhoto = (images: ImageDecorator[]) => {
    console.log(images[0]);
    return images[0].src;
  }

  getCoverPhotoAlt = (images: ImageDecorator[]) => {
    return images[0].alt;
  }

  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
  }  

  showBook = async () => {
    this.setState({ visible: true });
  }

  getAnyPageUrlUpdates = async () => {
    try {
      if (!this.state.loading) {
        return;
      }
  
      var email = this.state.email;
      var story = this.state.story;
      var images = this.state.images;
      if (story === undefined) {
        this.forceUpdate();
        return;
      }
  
      await this.delay(8000);
      let sceneList = await this.getScenes(story);
      sceneList = sceneList.filter((scene: Scene) => scene.sceneState !== 1 && scene.sceneState !== 2)
      if (!this.arraysEqual(sceneList, this.state.scenes)) {
        console.log("arrays quest");
        let urls = await this.getPageUrls(story);
        if (story.storyType === 0) {
          this.setState({ story: story, images: urls, scenes: sceneList, storyLoaded: true, loading: false, prompt: '', dialouge: '', caption: '' });
        } else {
          this.setState({ story: story, images: urls, scenes: sceneList, storyLoaded: true, loading: false, prompt: '', dialouge: '', caption: '' });
        }
        this.forceUpdate();
        return;
      }
  
      this.getAnyPageUrlUpdates();
    } catch (e) {
      if (this.state.numOfFailuresCount > 2) {
        console.log(e);
        alert("Some error occurred when fetching new panel");
        this.setState({ loading: false, numOfFailuresCount: 0 });
  
        // TODO: CLEAR TIMEOUT HERE...
        //clearTimeout(this.recursionTimeout);
        return;
      }

      // TODO: TEST THIS BY ADDING A LOT OF PANELS
      const newCount = this.state.numOfFailuresCount + 1;
      this.setState({ numOfFailuresCount: newCount });
      this.getAnyPageUrlUpdates();
    }
  };

  arraysEqual(a: Scene[], b: Scene[]) {
    if (a.length !== b.length) return false;
  
    const compareScenes = (scene1 : Scene, scene2: Scene) => {
      if (scene1.id < scene2.id) return -1;
      if (scene1.id > scene2.id) return 1;
      return 0;
    };
  
    const aSorted = a.slice().sort(compareScenes);
    const bSorted = b.slice().sort(compareScenes);
  
    return aSorted.every((val, index) => {
      if (typeof val === 'object' && val !== null && typeof bSorted[index] === 'object' && bSorted[index] !== null) {
        return JSON.stringify(val) === JSON.stringify(bSorted[index]);
      } else {
        return val === bSorted[index];
      }
    });
  }

  delay = (ms : number) => new Promise(res => setTimeout(res, ms));

  getPageUrls = (story: Story) =>{
    return API.get("files", "/" + story.fileId + "/customStories", null);
  }

  async fetchUserInfo(email : string) {
    try {
      return await API.get("checkout", "/users/" + email, {headers: {
        'x-api-key': 'K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ'
      }});

    } catch (error) {
      console.log('Error fetching user info:', error);
    }
  }


  getScenes = (story: Story) =>{
    return API.get("story", "/" + story.id + "/customStory/scenes", null);
  }

  getStory = (storyId: string) =>{
    return API.get("story", "/" + this.state.storyId, null);
  }

  setVisible = async (visible: boolean) => {
    this.setState({ visible: visible });
  }

  publishStory = async () => {
    if (this.state.story === undefined) {
      return;
    }
    try {
      await API.post("story", "/" + this.state.story.id + "/publish", null);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  nextPage() {
    let imagesLength = this.state.images.length;
    let currentPage = this.state.currentPage;
    if (currentPage < imagesLength - 2) {
      console.log("Turning page" + currentPage);
      currentPage++;
      currentPage++;
      this.setState({currentPage: currentPage});
    } 
    this.forceUpdate();
  }

  onPromptChanged = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      prompt: target.value,
      promptValid: 'success' 
    });
  }

  onDialougeChanged = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      dialouge: target.value,
      dialougeValid: 'success' 
    });
  }

  onCaptionChanged = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      caption: target.value,
      captionValid: 'success' 
    });
  }

  previousPage() {
    let currentPage = this.state.currentPage;
    if (currentPage > 1) {
      console.log("Previous page");
      currentPage--;
      currentPage--;
      this.setState({currentPage: currentPage});
    } 
    this.forceUpdate();
  }

  disableAddingPanel(scenes: Scene[]): boolean {
    if (this.state.user?.customStoryCreationSubscriptionId !== null && this.state.user?.isCustomStoryCreationCancelled === false) {
      return false;
    }

    const count = scenes.filter((scene) => scene.sceneState === 4).length;
    return count > 10;
  }

  disablePanelReplace(scenes: Scene[]): boolean {
    if (this.state.user?.customStoryCreationSubscriptionId !== null && this.state.user?.isCustomStoryCreationCancelled === false) {
      return false;
    }

    const count = scenes.filter((scene) => scene.sceneState === 3).length;
    return count > 3;
  }

  sendToHeadset = async () => {
    if (this.state.story === undefined) {
      return;
    }
    var email = this.state.email;    
    if (email == null) {
      console.log("Email information is missing. Please sign out and back in.")
      return;
    }

    try {
      await API.post("story", "/file-id/" + this.state.story.fileId + "/for-user/" + email, {
        headers: {
          'x-api-key': 'K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ'
        }
      });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isVisible = currentScrollPos === 0;
    this.setState({ isTopBarVisible: isVisible });
  };


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleUpdateButtons() {
    const buttonShow = !this.state.showUpdateButtons;
    console.log(buttonShow);
    this.setState({showUpdateButtons: buttonShow});
    this.forceUpdate();
  };

  render() {
    if (!this.state.storyLoaded) {
      return (
        <div className="white-box">
          <div className="media">
            <div className="media-left media-middle">
              <div className="loader-no-margin" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>

          {this.state.story != undefined &&
          <div className={`top-bar ${!this.state.isTopBarVisible ? 'hidden' : ''}`}>
            <span>
              <span>{this.state.story.title}&nbsp;</span>
              <span><small>{`(${this.getPrettyDate(this.state.story.createdAt)})`}</small></span>
              {/* <span className="switch">
                <button
                  className={`switch-button ${this.state.showUpdateButtons ? 'active' : ''}`}
                  onClick={() => this.toggleUpdateButtons()}>
                    View Edit Buttons
                </button>
              </span> */}

              {/* <div className="switch">
          
      </div> */}
            </span>
          </div>

          }
          <br></br>
          <br></br>

          <br></br>
            <div>
            {this.state.scenes
              .sort((a, b) => a.sceneCount - b.sceneCount)
              .map((scene, index) =>
              scene.sceneState === 1 || scene.sceneState === 2 ? null : 
                <div className="row" key={index}>
                  <br></br>
                  <div className="col-sm-12 col-md-12 position-relative">
                    <br></br>
                    <img src={this.state.images[(scene.sceneCount + 1)].src} className="CustomViewImagePanel" alt="Cover photo" /> 
                    {
                      // this.state.email === this.state.story?.userId &&
                      //   <div className="button-container position-absolute">
                      //     <OverlayTrigger
                      //       placement="left"
                      //       overlay={<Tooltip id="replace-tooltip">Replace panel</Tooltip>}
                      //     >
                      //       <Button
                      //         bsSize="large"
                      //         onClick={() => this.replacePanel(scene)}
                      //         disabled={(this.state.loading == true) || (this.disablePanelReplace(this.state.scenes))}
                      //         className="scene-button"
                      //       >
                      //         {this.state.loading && <Glyphicon glyph="refresh" className="spinning" />}
                      //         <Glyphicon glyph="pencil" /> {/* Edit icon */}
                      //       </Button>
                      //     </OverlayTrigger>
                      //     <OverlayTrigger
                      //       placement="left"
                      //       overlay={<Tooltip id="delete-tooltip">Delete panel</Tooltip>}
                      //     >
                      //       <Button 
                      //         bsSize="large"
                      //         onClick={() => this.deletePanel(scene)}
                      //         disabled={(this.state.loading == true)}
                      //         className="scene-button"
                      //       >
                      //         {this.state.loading && <Glyphicon glyph="refresh" className="spinning" />}
                      //         <Glyphicon glyph="trash" /> {/* Delete icon */}
                      //       </Button>
                      //     </OverlayTrigger>
                      //   </div>
                    }
                  </div>
                </div>
              )}
              <br></br>
              <br></br>
              {
                this.state.email !== '' && this.state.email === this.state.story?.userId &&
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: '40vh'     
                    }}>
                  <button
                      className="custom-button"
                      type="submit"
                      disabled={
                        this.state.loading == true ||
                        this.disableAddingPanel(this.state.scenes)
                      }
                      onClick={this.onAddNewPanel}
                      >
                                  {this.state.loading && (
                      <Glyphicon glyph="refresh" className="spinning" />
                    )}
                    {!this.state.loading && (
                      <p><Glyphicon glyph="plus" /> Add New Panel</p>
                      // <Glyphicon glyph="plus" /> 
                    )}
                  </button>
              </div>
              }
              {
                this.state.email === '' || (this.state.email !== this.state.story?.userId) &&
                  <div style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '80vh'
                  }}>
                    <img
                      src="https://media.giphy.com/media/lpWpSUMr9w9cETa14Z/giphy.gif"
                      alt="Anime"
                      style={{
                        maxWidth: '100%',
                        height: 'auto'
                      }}
                    />
                  </div>
              }

          <div style={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '40vh'
          }}>
            <CustomStoryPanelButtonForm storyId={this.state.storyId} userId={this.state.email}></CustomStoryPanelButtonForm>
          </div>

          
              {
                this.state.email !== '' && this.state.email === this.state.story?.userId &&
  <div>

  <Grid>
    <Row>
      <Col xs={2}>
        {/* <Button
            // bsSize="large"
            // type="submit"
            className="custom-button"
            block
            onClick={this.onAddNewPanel}
            disabled={
              this.state.loading == true ||
              this.disableAddingPanel(this.state.scenes)
            }
          >
            {!this.state.loading && (
              <p>Create New Story</p>
            )}
          </Button>
          <button
            className="custom-button"
            type="submit"
            disabled={
              this.state.loading == true ||
              this.disableAddingPanel(this.state.scenes)
            }
            onClick={this.onAddNewPanel}
            >
              Create New Story
          </button> */}
      </Col>
      {/* <Col xs={8}>
        <CustomStoryPanelButtonForm></CustomStoryPanelButtonForm>
      </Col> */}
      <Col xs={2}>
          {/* <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <h3 className="text-white-header">Want to create more panels for this story?</h3>
            <br></br>
            <FormGroup controlId="prompt">
              <ControlLabel><p className="text-white-small">Prompt <small>(Optional)</small></p></ControlLabel>
              <FormControl
                name="prompt"
                type="text"
                bsSize="large"
                value={this.state.prompt}
                disabled={this.state.loading}
                onChange={this.onPromptChanged}
              />
              <FormControl.Feedback />
              <HelpBlock>
                <p className="text-white-small">Enter any story prompt for the next panel</p>
              </HelpBlock>
            </FormGroup>
            <FormGroup controlId="dialogue">
              <ControlLabel><p className="text-white-small">Dialogue <small>(Optional)</small></p></ControlLabel>
              <FormControl
                name="dialogue"
                type="text"
                bsSize="large"
                value={this.state.dialouge}
                disabled={this.state.loading}
                onChange={this.onDialougeChanged}
              />
              <FormControl.Feedback />
              <HelpBlock>
                <p className="text-white-small">Enter any custom dialogue you want added to the next panel</p>
              </HelpBlock>
            </FormGroup>
            <FormGroup controlId="caption">
              <ControlLabel><p className="text-white-small">Caption <small>(Optional)</small></p></ControlLabel>
              <FormControl
                name="caption"
                type="text"
                disabled={this.state.loading}
                bsSize="large"
                value={this.state.caption}
                onChange={this.onCaptionChanged}
              />
              <FormControl.Feedback />
              <HelpBlock>
                <p className="text-white-small">Enter any caption you want added to the next panel</p>
              </HelpBlock>
            </FormGroup>
          </div> */}
          {/* <Button
            // bsSize="large"
            // type="submit"
            className="custom-button"
            block
            onClick={this.onAddNewPanel}
            disabled={
              this.state.loading == true ||
              this.disableAddingPanel(this.state.scenes)
            }
          >
            {this.state.loading && (
              <Glyphicon glyph="refresh" className="spinning" />
            )}
            {!this.state.loading && (
              // <p>Add Panel</p>
              <Glyphicon glyph="plus" /> 
            )}
          </Button> */}
        {/* </OverlayTrigger> */}
      </Col>
      {/* <Col xs={2}></Col> */}
    </Row>

  </Grid>
  <br></br>
    <br></br>
    <br></br>
</div>
                // <div className="centered-container">
                //   {/* <p className="text-white">Generate New Panels</p> */}
                //   <OverlayTrigger
                //     placement="left"
                //     overlay={<Tooltip id="add-tooltip">Add new panel</Tooltip>}
                //   >
                //     <Button
                //       bsSize="large"
                //       type="submit"
                //       className="centered-button"
                //       onClick={this.onAddNewPanel}
                //       disabled={(this.state.loading == true) || (this.disableAddingPanel(this.state.scenes))}>
                //       {this.state.loading && <Glyphicon glyph="refresh" className="spinning" />}<Glyphicon glyph="plus" /> {/* Delete icon */}
                //     </Button>
                //   </OverlayTrigger>
                //   <FormGroup controlId="prompt">
                //     <ControlLabel>Prompt</ControlLabel>
                //     <FormControl
                //       name="prompt"
                //       type="text"
                //       bsSize="large"
                //       value={this.state.prompt}
                //       onChange={this.onPromptChanged} />
                //     <FormControl.Feedback />
                //     <HelpBlock><p className="text-white-small">Enter any prompt for next story</p>
                //     </HelpBlock>
                //   </FormGroup>
                //   <FormGroup controlId="prompt">
                //     <ControlLabel>Dialouge</ControlLabel>
                //     <FormControl
                //       name="prompt"
                //       type="text"
                //       bsSize="large"
                //       value={this.state.prompt}
                //       onChange={this.onPromptChanged} />
                //     <FormControl.Feedback />
                //     <HelpBlock><p className="text-white-small">Enter any custom dialouge you want added</p>
                //     </HelpBlock>
                //   </FormGroup>
                //   <FormGroup controlId="prompt">
                //     <ControlLabel>Caption</ControlLabel>
                //     <FormControl
                //       name="prompt"
                //       type="text"
                //       bsSize="large"
                //       value={this.state.prompt}
                //       onChange={this.onPromptChanged} />
                //     <FormControl.Feedback />
                //     <HelpBlock><p className="text-white-small">Enter any prompt for next story</p>
                //     </HelpBlock>
                //   </FormGroup>
                // </div>
              }
          </div>
    </div>
    );
  }
}

export default CustomStoriesPanel;

