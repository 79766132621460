export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-east-1",
    API_URL: "https://ed16bicbd6.execute-api.us-east-1.amazonaws.com/prod",
  },
  fileImportApiGateway: {
    REGION: "us-east-1",
    API_URL: "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/files",
  },
  storyGenerateApiGateway: {
    REGION: "us-east-1",
    API_URL: "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/story",
  },  
  checkoutApiGateway: {
    REGION: "us-east-1",
    API_URL: "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/checkout",
  },
  episodesApiGateway: {
    REGION: "us-east-1",
    API_URL: "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/episodes",
  },  
  charactersApiGateway: {
    REGION: "us-east-1",
    API_URL: "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/characters",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_HeTjBo0fe",
    APP_CLIENT_ID: "31asum38mv741sr3g3l5vsdjnu",
    IDENTITY_POOL_ID: "us-east-1:7ea95c5e-e9dd-4f15-a76d-84508c5661ac"
  }
};