import React, { Component } from "react";
import "../../common/hero/hero.css";
import { API } from "aws-amplify";
import "./customStoryImport/customStoryImport.css";
import { Story } from "./CustomStories";
import CustomStoriesRowNew from "./CustomStoriesRowNew";

interface PublicStoriesProps {
  isXrReaderView: boolean;
}

export interface Metadata {
  bookName: string;
  author: string;
  bookCoverS3Url: string;
}

interface PublicStoriesState {
  isLoading: boolean;
  isStoryBeingGenerated: boolean;
  stories: Story[];
  isXrReaderView: boolean;
  currentPage: number;
}

export default class CustomStories extends Component<PublicStoriesProps, PublicStoriesState> {
  private loadingRef: HTMLDivElement | null = null;

  constructor(props: PublicStoriesProps) {
    super(props);

    console.log(props.isXrReaderView);
    this.state = {
      isStoryBeingGenerated: false,
      isLoading: true,
      stories: [],
      isXrReaderView: props.isXrReaderView,
      currentPage: 1,
    };
  }

  observer = new IntersectionObserver((entries) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting) {
      this.fetchMoreStories();
    }
  });

  fetchMoreStories = async () => {
    var nextPage = this.state.currentPage + 1;
    try {
      const storiesResponse = await this.listPublicStories(nextPage);
      console.log(storiesResponse);
      var currentStories = this.state.stories;
      currentStories.push(...storiesResponse.stories);
      this.setState({
        stories: currentStories,
        isLoading: false,
        currentPage: nextPage,
      });
    } catch (e) { 
      alert("Failed to load next page");
    }
  };

  async componentDidMount() {
    try {
      const storiesResponse = await this.listPublicStories(1);
      console.log(storiesResponse);
      this.setState({
        stories: storiesResponse.stories,
        isLoading: false,
      });
      if (this.loadingRef) {
        this.observer.observe(this.loadingRef);
      }
    } catch (e) {
      alert(e);
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  listPublicStories = (pageToFetch: number) => {
    return API.get("story", "/public/paginated?page=" + pageToFetch, {
      headers: {
        "x-api-key": "K37BiZoU6T9eTLIkTMIvE1C0JKvEvibe5wvzeFdQ",
      },
    });
  };

  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${
      date.getHours()
    }:${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;
  };

  render() {
    return (
      <div className="Category">
        {/* {this.state.isXrReaderView === undefined && (
          <div className="white-box">
            <h2 className="text-white-header text-center">
              View featured stories shared by the public
            </h2>
          </div>
        )} */}
        <div className="row display-fle new">
          {!this.state.isLoading &&
            this.state.stories.length !== 0 &&
            this.state.stories
              .filter((file) => file.status === 2)
              .sort((file1, file2) => file2.createdAt - file1.createdAt)
              .map((file) => (
                <div
                  key={file.id}
                  // style={{
                  //   height: `${Math.floor(Math.random() * 5) + 50}px`,
                  // }}
                >
                  <CustomStoriesRowNew
                    email=""
                    story={file}
                    key={file.id}
                    progressBar={-80}
                    isXrReaderView={this.state.isXrReaderView}
                    isCustomStyle={false}
                    />
                  </div>
                  ))}
                </div>
                <div className="loading" ref={(loadingRef) => (this.loadingRef = loadingRef)}></div>
              </div>
            );
        }
}