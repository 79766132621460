import React from 'react';
import { Button } from 'react-bootstrap';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
        <Button
        bsSize="small"
        className="centered-button"
        disabled={currentPage === 1}
        onClick={handlePreviousClick}>
        Back
      </Button>
      <span className='text-white'>
        &nbsp;Page {currentPage} of {totalPages} &nbsp;
      </span>
      <Button
        bsSize="small"
        className="centered-button"
        disabled={currentPage === totalPages}
        onClick={handleNextClick}>
        Next
      </Button>
    </div>
  );
};

export default Pagination;
