import React from "react";
import "../../common/styles/productRow.css";
// You need to import the CSS only once
import { API } from "aws-amplify";
import Viewer from "react-viewer";
import {Story} from "./CustomStories";
import "../../common/styles/gallery.css";
import { Redirect } from "react-router-dom";
import { ImageDecorator } from "./CustomStoriesRow";

interface CustomStoriesRowNewProps {
  story: Story | undefined;
  progressBar: number
  isXrReaderView : boolean;
  isCustomStyle :boolean;
  email : string;
}

interface CustomStoriesRowNewState {
  story: Story | undefined;
  images: ImageDecorator[];
  visible: boolean;
  progressBar: number
  isXrReaderView : boolean;
  customStoryViewOpen : boolean;
  isCustomStyle :boolean;
  redirectToCustomStories :boolean;
  email : string;
  redirectToCustomStoryId: string;
}


export class CustomStoriesRowNew extends React.Component<CustomStoriesRowNewProps, CustomStoriesRowNewState> {
  constructor(props: CustomStoriesRowNewProps) {
    super(props);

    this.state = {
      story: undefined,
      images: [],
      visible: false,
      progressBar: props.progressBar,
      isXrReaderView: props.isXrReaderView,
      isCustomStyle: props.isCustomStyle,
      email: props.email,
      redirectToCustomStoryId: '',
      redirectToCustomStories: false,
      customStoryViewOpen: false
    };
  }

  async componentDidMount() {
    try {
      const story = this.props.story;
      this.setState({ story: story });
      if (story === undefined) {
        return;
      }

      if (story.fileId !== null) {
        const urls = await this.getPageUrls(story);      
        this.setState({images: urls});
      }

    } catch (e) {
      alert(e);
    }
  }

  getStatusString = (statusNumber: number) => {
    switch(statusNumber) {
      case 0:
        return 'IN PROGRESS';
      case 1:
        return 'FAILED';
      case 2:
        return 'COMPLETE';
      default:
        return 'DELETED';
      }
  }

  getCoverPhoto = (images: ImageDecorator[]) => {
    console.log(images[0]);
    return images[0].src;
  }

  getCoverPhotoAlt = (images: ImageDecorator[]) => {
    return images[0].alt;
  }

  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
  }  

  showBook = async () => {
    this.setState({ visible: true });
  }

  getPageUrls = (story: Story) =>{
    return API.get("files", "/" + story.fileId + "/customStories", null);
  }

  setVisible = async (visible: boolean) => {
    this.setState({ visible: visible });
  }

  openCustomView = async (customStoryViewOpen: boolean) => {
    this.setState({ customStoryViewOpen: !customStoryViewOpen });
  }



  onImageClicked = (storyId: string) => {
    // const target = event.target as HTMLInputElement;
    this.setState({
      redirectToCustomStories: true,
      redirectToCustomStoryId : storyId
    });
  }

  render() {
    if (!this.state.story) {
      return (
        <div className="white-box">
          <div className="media">
            <div className="media-left media-middle">
              <div className="loader-no-margin" />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.customStoryViewOpen) {
      return (
        <div className="white-box">
          <div className="media">
            <Redirect to={{
                pathname: '/customStories/' + this.state.story.id
            }}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
      <div className={this.state.isCustomStyle ? 'col-sm-11 col-md-11' : 'col-sm-4 col-md-4'}>

      {/* <div className={this.state.isCustomStyle ? 'col-sm-11 col-md-11' : 'col-sm-4 col-md-4'}> */}
      <br></br>
      <div className="customnail-new">
      <div>
        <div>
          <div >
            {this.state.story.status == 2 && this.state.images.length != 0 &&
              <div className="image-container">
                <img
                  src={this.getCoverPhoto(this.state.images)}
                  alt="Anime"
                  className={`img-responsive genre-image-new ${this.state.redirectToCustomStoryId === this.state.story.id ? "selected" : ""}`}
                  onClick={() => this.openCustomView(this.state.customStoryViewOpen)}
                />
                  <div className="image-info">
                    <h5>{this.state.story.title}</h5>
                    <h6>{`${this.getPrettyDate(this.state.story.createdAt)}`}</h6>
                  </div>
                  <div className="image-info-mobile">
                  <h5>{this.state.story.title}</h5>
  <h6>{`${this.getPrettyDate(this.state.story.createdAt)}`}</h6>
</div>
              </div>
            }
          {/* {this.state.story.status == 2 && this.state.images.length != 0 
           ?<img
           src={this.getCoverPhoto(this.state.images)}
           alt="Anime"
           className={`img-responsive genre-image-new ${this.state.redirectToCustomStoryId === this.state.story.id ? "selected" : ""}`}
           onClick={() => this.openCustomView(this.state.customStoryViewOpen)}
          //  style={{
          //    height: `${Math.floor(Math.random() * (320 - 100 + 1) + 400)}px`,
          //  }}
         />
          // ? <img src={this.getCoverPhoto(this.state.images)} className="StoryImage" alt="Cover photo" />  
          : <img src={image} className="StoryImage" alt="Custom file" />        
          }  */}
          </div>
          {/* <div className={this.state.isCustomStyle ? 'text-black' : 'text-font'}>
            <h5>Prompt: {this.state.story.prompt}</h5>
            <h5>{this.state.story.status == 1 ? "Generation Failed (Retry Pending)" : this.state.story.title} </h5>
            <h6>{`${this.getPrettyDate(this.state.story.createdAt)}`}</h6> 
            <Viewer 
              visible={this.state.visible}
              onClose={() => { this.setVisible(false); } }
              images={this.state.images}
              rotatable={false}
              scalable={false}
              drag={false}
              defaultScale={1.25}
              downloadable={true}
            />
          </div> */}
        </div>
      </div>
      </div>  
    </div>
    </div>
    );
  }
}

export default CustomStoriesRowNew;

