import React from "react";
import { Redirect } from 'react-router';
import { FormGroup, FormControl, ControlLabel, Button, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import "./login.css";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface PasswordResetProps {
  isAuthenticated: boolean;
  saveUsersEmail: (email: string) => void;
  userHasAuthenticated: (authenticated: boolean) => void;
}

interface PasswordResetState {
  loading: boolean;
  redirect: boolean;
  email: string;
  newPassword: string;
  confirmationCode: string,
  step: number,
  emailValid: "success" | "error" | "warning" | undefined;
  passwordValid: "success" | "error" | "warning" | undefined;
  confirmationCodeValid: "success" | "error" | "warning" | undefined;
}

export default class PasswordReset extends React.Component<PasswordResetProps, PasswordResetState> {
  constructor(props: PasswordResetProps) {
    super(props);

    this.state = {
      loading: false,
      redirect: false,
      email: "",
      confirmationCode: "",
      newPassword: "",
      step: 1,
      emailValid: undefined,
      passwordValid: undefined,
      confirmationCodeValid: undefined,
    };
  }

  resetPassword = async () => {
    console.log(this.state.email);
    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({step: 2});
    } catch (error) {
      console.error("Error sending password reset code:", error);
    }
  };

  confirmReset = async () => {
    try {
      await Auth.forgotPasswordSubmit(this.state.email, this.state.confirmationCode, this.state.newPassword);
      this.setState({redirect: true});
    } catch (error) {
      alert("Error resetting password:" + error.message);
    }
  };

  onEmailChange = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      email: target.value,
      emailValid: emailRegex.test(target.value.toLowerCase()) ? 'success' : 'error'
    });
  }

  onPasswordChange = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      newPassword: target.value,
      passwordValid: target.value.length < 8 ? 'error' : 'success'
    });
  }

  onConfirmationCodeChanged = (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      confirmationCode: target.value,
      confirmationCodeValid: 'success'
    });
  }

  render() {
    if (this.state.redirect) return <Redirect to='/login' />

    if (this.state.step === 1) {
      return (
        <div className="Login">
          <h2 className="text-center text-white">Reset Password</h2>
          <br></br>
          <Form>
            <FormGroup controlId="email">
              <ControlLabel>Email</ControlLabel>
              <FormControl
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.onEmailChange}
              />
              <br></br>
            </FormGroup>
            <Button               
            disabled={
              this.state.email === ''}
               onClick={this.resetPassword}>Send Confirmation Code</Button>
          </Form>
        </div>
      );
    } else if (this.state.step === 2) {
      return (
        <div className="Login">
          <h2>Confirm Reset</h2>
          <Form>
            <FormGroup controlId="email">
              <ControlLabel><p className="text-center text-white">Email</p></ControlLabel>
              <FormControl type="email" placeholder="Email" value={this.state.email} readOnly />
            </FormGroup>
            <FormGroup controlId="confirmationCode">
              <ControlLabel><p className="text-center text-white">Confirmation Code</p></ControlLabel>
              <FormControl
                type="text"
                placeholder="Confirmation Code"
                value={this.state.confirmationCode}
                bsSize="large"
                onChange={this.onConfirmationCodeChanged}
              />
            </FormGroup>
            <FormGroup controlId="newPassword">
              <ControlLabel><p className="text-center text-white">New Password</p></ControlLabel>
              <FormControl
                type="password"
                placeholder="New Password"
                value={this.state.newPassword}
                bsSize="large"
                onChange={this.onPasswordChange}
              />
            </FormGroup>
            <Button           
              bsSize="large"
              // type="submit"
              disabled={
              this.state.passwordValid !== 'success'
              || this.state.confirmationCodeValid !== 'success'}
              onClick={this.confirmReset}>Confirm Reset
              </Button>
          </Form>
        </div>
      );
    } else {
      return <div>Password reset successfully!</div>;
    }
  }
}