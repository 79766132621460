import React from "react";
import { Route, Switch } from "react-router-dom";
import Checkout from "./modules/checkout/Checkout";
import CheckoutConfirm from "./modules/checkout/CheckoutConfirm";
import Home from "./modules/signup/Home";
import Login from "./modules/signup/Login";
import NotFound from "./modules/notFound/NotFound";
import Signup from "./modules/signup/Signup";
import CategoryView from "./modules/category/CategoryView";
import BestSellers from "./modules/bestSellers/BestSellers";
import SearchView from "./modules/search/SearchView";
import PropsRoute from "./common/PropsRoute";
import PastDownloads from "./modules/pastDownloads/PastDownloads";
import CustomStories from "./modules/customStories/CustomStories";
import CustomStoryImport from "./modules/customStories/customStoryImport/CustomStoryImport";
import LandingPage from "./modules/signup/LandingPage";
import PublicStories from "./modules/customStories/PublicStories";
import CustomStoriesPanel from "./modules/customStories/CustomStoriesPanel";
import PasswordReset from "./modules/signup/PasswordReset";
import ProfilePage from "./modules/signup/ProfilePage";
import CustomStoriesImportWrap from "./modules/customStories/CustomStoriesImportWrap";
import YourStories from "./modules/customStories/YourStories";

interface RouteProps {
  email: string;
  saveUsersEmail: (email: string) => void;
  isAuthenticated: boolean;
  userHasAuthenticated: (authenticated: boolean) => void;
}

export const Routes: React.SFC<RouteProps> = (childProps) =>
  <Switch>
    <PropsRoute path="/" exact component={CustomStories} props={childProps} />
    <PropsRoute path="/login" exact component={Login} props={childProps} />
    <PropsRoute path="/logout" exact component={CustomStories} props={childProps} />
    <PropsRoute path="/signup" exact component={Signup} props={childProps} />
    <PropsRoute path="/passwordReset" exact component={PasswordReset} props={childProps} />
    <PropsRoute path="/profile" exact component={ProfilePage} props={childProps} />
    {/* <PropsRoute path="/subscriptionInformation" exact component={SubscriptionInformation} props={childProps} /> */}
    {/* <PropsRoute path="/customFileImport" exact component={CustomFileImport} props={childProps} /> */}
    <PropsRoute path="/customStoriesCreate" exact component={CustomStoryImport} props={childProps} />
    {/* <PropsRoute path="/publicStories" exact component={PublicStories} props={childProps} /> */}
    <Route path="/publicStories" exact component={PublicStories} />
    <Route path="/privateStories" exact component={YourStories} />
    {/* <Route path="/best" exact component={BestSellers} /> 
    <Route path="/category/:id" exact component={CategoryView} /> */}
    <Route path="/customStories" exact component={CustomStoriesImportWrap} />
    <Route path="/customStories/:id" exact component={CustomStoriesPanel} />
    {/* <Route path="/pastDownloads" exact component={PastDownloads} />
    <Route path="/about" exact component={LandingPage} /> */}
    {/* <Route path="/customStories" exact component={CustomStories} /> */}
    {/* <Route path="/checkout" exact component={Checkout} />
    <Route path="/checkout-confirm" exact component={CheckoutConfirm} />
    <Route path="/search/:id" exact component={SearchView} /> */}
    <Route component={NotFound} />
  </Switch>;