import { API } from "aws-amplify";
import React, { useState } from "react";
import { Form, Button, ControlLabel, FormControl, FormGroup } from "react-bootstrap";

interface CustomStoryPanelButtonFormProps {
  storyId: string;
  userId: string | undefined;
}

const CustomStoryPanelButtonForm: React.FC<CustomStoryPanelButtonFormProps> = ({ storyId, userId }) => {
  const [simpleFeedback, setSimpleFeedback] = useState<boolean | undefined>(undefined);
  const [feedback, setFeedback] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [story, setStory] = useState(storyId);
  const [passedUserId, setPassedUserId] = useState(userId);

  const handleFeedbackChange = (event: React.FormEvent<FormControl>) => {
    // const target = event.target as HTMLInputElement;
    // setFeedback({...feedback, [target.name]: target.value});
    const target = event.target as HTMLInputElement;
    setFeedback(target.value);
    // setFeedback({...feedback, [target.name]: target.value});
  };
  
  const handleThumbsUp = () => {
    setSimpleFeedback(true);
    // setFeedbackSubmitted(true);
  };

  const handleThumbsDown = () => {
    setSimpleFeedback(false);
  };

  
  const handleSubmitFeedback = async (event: React.FormEvent<FormControl>) => {
    const target = event.target as HTMLInputElement;
    try {
      await API.post("story", "/feedback", {
        body: {
          StoryId: story,
          UserId: passedUserId,
          LikedStory: simpleFeedback,
          FeedbackText: feedback
        }
      });
    } catch (error) {
        alert(error);
        return;
    }

    setFeedbackSubmitted(true);
  }


  return (
    <div>
      {userId !== '' && !feedbackSubmitted && simpleFeedback === undefined && (
        <FormGroup controlId="formWritingFeedback">
          <ControlLabel>
            <br></br>
            <p className="text-white-medium">How was the story and graphics?</p>
            <br></br>
          </ControlLabel> 
          <span style={{display: 'flex'}}>
              <img
                background-color="black"
                src="https://media.giphy.com/media/XDq0oCXcO3H37JSnWq/giphy.gif"
                alt="Question Image"
                className={`img-responsive genre-image`}
                width={150}
                height={150}
                onClick={handleThumbsUp}
              />
              <img
                background-color="black"
                src="https://media.giphy.com/media/TjvN1tVi00SuED5qoc/giphy.gif"
                alt="Question Image"
                className={`img-responsive genre-image`}
                width={150}
                height={150}
                onClick={handleThumbsDown}
              />
            </span>
        </FormGroup>
      )}
      {!feedbackSubmitted && simpleFeedback !== undefined && (
        <Form onSubmit={handleSubmitFeedback}>
          <FormGroup controlId="formOtherFeedback">
            <ControlLabel><p className="text-white-small">Is their anything we can change to improve your overall experience?</p></ControlLabel>
            <FormControl bsSize="large" type="text" name="other" value={feedback} onChange={handleFeedbackChange} />
          </FormGroup>
          <Button bsSize="large" className="primary" 
          // type="submit"
          onClick={handleSubmitFeedback}
          >Submit</Button>
        </Form>
      )}
      {feedbackSubmitted && <p className="text-white-large">Thank you for your feedback!</p>}
    </div>
  );
};

export default CustomStoryPanelButtonForm;