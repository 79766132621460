import React from "react";
import "../../common/styles/productRow.css";
import image from "../../images/hero/fileSmall.png";
import {  Button, ButtonGroup, DropdownButton, Glyphicon, MenuItem} from "react-bootstrap";
// You need to import the CSS only once
import { API } from "aws-amplify";
import Viewer from "react-viewer";
import {Story} from "./CustomStories";
import "../../common/styles/gallery.css";
import { Redirect } from "react-router-dom";
import axios from "axios";

interface CustomStoriesRowProps {
  story: Story | undefined;
  progressBar: number
  isXrReaderView : boolean;
  isCustomStyle :boolean;
  email : string;
}

interface CustomStoriesRowState {
  story: Story | undefined;
  images: ImageDecorator[];
  visible: boolean;
  progressBar: number
  isXrReaderView : boolean;
  customStoryViewOpen : boolean;
  isCustomStyle :boolean;
  redirectToCustomStories :boolean;
  email : string;
  redirectToCustomStoryId: string;
}

export interface ImageDecorator {
  src: string;
  alt?: string;
  downloadUrl?: string;
  defaultSize?: ViewerImageSize;
}

export interface ViewerImageSize {
  width: number;
  height: number;
}

export class CustomStoriesRow extends React.Component<CustomStoriesRowProps, CustomStoriesRowState> {
  constructor(props: CustomStoriesRowProps) {
    super(props);

    this.state = {
      story: undefined,
      images: [],
      visible: false,
      progressBar: props.progressBar,
      isXrReaderView: props.isXrReaderView,
      isCustomStyle: props.isCustomStyle,
      email: props.email,
      redirectToCustomStoryId: '',
      redirectToCustomStories: false,
      customStoryViewOpen: false
    };
  }

  async componentDidMount() {
    try {
      const story = this.props.story;
      this.setState({ story: story });
      if (story === undefined) {
        return;
      }

      if (story.fileId !== null) {
        console.log("story releoeded" + story.fileId);
        const urls = await this.getPageUrls(story);      
        this.setState({images: urls});
      }

    } catch (e) {
      alert(e);
    }
  }

  getStatusString = (statusNumber: number) => {
    switch(statusNumber) {
      case 0:
        return 'IN PROGRESS';
      case 1:
        return 'FAILED';
      case 2:
        return 'COMPLETE';
      default:
        return 'DELETED';
      }
  }

  getCoverPhoto = (images: ImageDecorator[]) => {
    console.log(images[0]);
    return images[0].src;
  }

  getCoverPhotoAlt = (images: ImageDecorator[]) => {
    return images[0].alt;
  }

  getPrettyDate = (dateNumber: number) => {
    const date = new Date(dateNumber * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
  }  

  showBook = async () => {
    this.setState({ visible: true });
  }

  getPageUrls = (story: Story) =>{
    return API.get("files", "/" + story.fileId + "/customStories", null);
  }

  setVisible = async (visible: boolean) => {
    this.setState({ visible: visible });
  }

  openCustomView = async (customStoryViewOpen: boolean) => {
    this.setState({ customStoryViewOpen: !customStoryViewOpen });
  }

  publishStoryToPublic = async (publishStoryId: string) => {
    // this.setState({ visible: visible });
  }

  publishStory = async () => {
    if (this.state.story === undefined) {
      return;
    }
    try {
      await API.post("story", "/" + this.state.story.id + "/publish", null);
    } catch (e) {
      console.log(e);
      // this.setState({progressBar: -100, redirect: false});
      return;
    }
  }

  deleteFile = async () => {
    if (this.state.story === undefined) {
      return;
    }
    try {
      await API.del("story", "/" + this.state.story.id + "/for-user/"+ this.state.email, null);
      this.forceUpdate();
    } catch (e) {
      console.log(e);
      // this.setState({progressBar: -100, redirect: false});
      return;
    }
    window.location.reload();
  }

downloadFile = async () => {
  try {
    var options = {
      headers: {
        'Content-Type': '',
        'Accept': 'application/pdf'
      }
    };

    const response = await axios.get(
      "https://78t9q54a9e.execute-api.us-east-1.amazonaws.com/Prod/api/xrr-backend/files/" + this.state.story?.fileId + "/name/test/pdf-version", options);
    //  const response = await API.get("files", "/"+ this.state.story?.fileId + "/name/test/pdf-version", null);
    if (response && response.data) {
      let binaryString = window.atob(response.data);

      let binaryLen = binaryString.length;
      
      let bytes = new Uint8Array(binaryLen);
      
      for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
      }
      
      let blob = new Blob([bytes], {type: "application/pdf"});
      
      let link = document.createElement('a');
      
      link.href = window.URL.createObjectURL(blob);
      link.download = `${this.state.story?.fileId}.pdf`;
      link.click();
      console.log(link);
      link.remove();
    } else {
      console.error("Error fetching download URL: response is null or undefined");
    }
  } catch (error) {
    console.error("Error fetching download URL", error);
  }
  };

  sendToHeadset = async () => {
    if (this.state.story === undefined) {
      return;
    }
    var email = this.state.email;

    try {
      await API.post("story", "/file-id/" + this.state.story.fileId + "/for-user/" + email, null);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  onImageClicked = (storyId: string) => {
    // const target = event.target as HTMLInputElement;
    this.setState({
      redirectToCustomStories: true,
      redirectToCustomStoryId : storyId
    });
  }

  render() {
    if (!this.state.story) {
      return (
        <div className="white-box">
          <div className="media">
            <div className="media-left media-middle">
              <div className="loader-no-margin" />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.customStoryViewOpen) {
      return (
        <div className="white-box">
          <div className="media">
            <Redirect to={{
                pathname: '/customStories/' + this.state.story.id
            }}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
      <div className={this.state.isCustomStyle ? 'col-sm-11 col-md-11' : 'col-sm-4 col-md-4'}>
      <br></br>
      <div className="customnail">
      <div>
        <div>
          <div>
            {this.state.story.status == 2 && this.state.images.length != 0 
            ?<img
                src={this.getCoverPhoto(this.state.images)}
                alt="Anime"
                className={`img-responsive genre-image ${this.state.redirectToCustomStoryId === this.state.story.id ? "selected" : ""}`}
                onClick={() => this.openCustomView(this.state.customStoryViewOpen)}
              />
            : 
              <div>
                {this.state.story.status === 0 
                  ? <img src="https://media.giphy.com/media/rLrJtwMyaWL5XA7qfO/giphy.gif" className="StoryImage" alt="Custom file" />
                  : <img src="https://media.giphy.com/media/3o6UBpHgaXFDNAuttm/giphy.gif" className="StoryImage" alt="Custom file" />}
              </div>
            } 
          </div>
          <div className={this.state.isCustomStyle ? 'text-black' : 'text-font'}>
            <h5>{this.state.story.status == 1 ? "Generation Failed (Retry Pending)" : this.state.story.title} </h5>
            <h5>{this.state.story.status == 0 && "In Progress"} </h5>
            <h6>{`${this.getPrettyDate(this.state.story.createdAt)}`}</h6> 
            <br></br>
            <ButtonGroup>
            <Button
              bsSize="medium"
              type="submit"
              onClick={() => this.openCustomView(this.state.customStoryViewOpen)}
              disabled={
                this.state.story.status == 1 || this.state.story.status == 0} >
              {this.state.story.status === 0 ? <Glyphicon glyph="refresh" className="spinning" /> : this.state.story.status == 1 ? "Failed" : "View Story"}
            </Button>
            {
            this.state.isXrReaderView === true && 
              <DropdownButton title={<span> <i className="fa-solid fa-ellipsis"></i></span>} id="bg-nested-dropdown" onClick={(e) => e.stopPropagation()}>
                <MenuItem eventKey="1">
                  <Button
                    block
                    bsSize="medium"
                    onClick={this.sendToHeadset}>
                    Download To Headset
                  </Button>
                </MenuItem>
            </DropdownButton>
            }
            {this.state.story.status === 2 && this.state.progressBar !== -80 ?
              <DropdownButton  title={<span> <i className="fa-solid fa-ellipsis"></i></span>} id="bg-nested-sdf" onClick={(e) => e.stopPropagation()}>
                <MenuItem>
                  <Button
                    block
                    bsSize="medium"
                    onClick={this.publishStory}>
                    Make Story Public
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    block
                    bsSize="medium"
                    onClick={this.deleteFile}>
                    Delete
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    block
                    bsSize="medium"
                    onClick={this.downloadFile}>
                    Download File
                  </Button>
                </MenuItem>
            </DropdownButton>
              : null
            } 
            </ButtonGroup>
            <Viewer 
              visible={this.state.visible}
              onClose={() => { this.setVisible(false); } }
              images={this.state.images}
              rotatable={false}
              scalable={false}
              drag={false}
              defaultScale={1.25}
              downloadable={true}
            />
          </div>
        </div>
      </div>
      </div>  
    </div>
    </div>
    );
  }
}

export default CustomStoriesRow;

